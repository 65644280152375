import { GridSortItem } from '@material-ui/data-grid';
import _ from 'lodash'
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IKeyValuePair } from '../../models/payloads/workorderPayloadResponse';
import { WorkOrderFieldValue } from '../../models/workOrderFieldValue';
import { ISearchState } from '../reducers/searchReducer';

export enum SearchActionTypes {
    SEARCH_GET = 'SEARCH_GET',
    SHOW_INDICATOR = 'SHOW_INDICATOR',
    SELECT_ROWS = 'SELECT_ROWS',
    RESET_SEARCH = 'RESET_SEARCH',
    SORT_SEARCH = 'SORT_SEARCH',
    BLOCK_UI = 'BLOCK_UI'
}

export interface ISearchAnyAction {
    type: SearchActionTypes;
    filters: WorkOrderFieldValue[];
    selectedRows: IKeyValuePair[],
    isSearching: boolean;
    sortItem: GridSortItem[];
    blockUI: boolean;
    rows: IKeyValuePair[];
}

export type SearchActions = ISearchAnyAction;

/*<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const searchAction: ActionCreator<ThunkAction<Promise<any>, ISearchState, null, ISearchAnyAction>> = (data: any) => {
    // console.log('Got Data in Search: ', data);
    return async (dispatch: Dispatch) => {
        try {
            dispatch({
                rows: data,
                type: SearchActionTypes.SEARCH_GET
            })
        } catch (err) {
            console.error('Error: ', err);
        }
    };
};

export const showIndicatorAction: ActionCreator<ThunkAction<Promise<any>, ISearchState, null, ISearchAnyAction>> = (filters: WorkOrderFieldValue) => {
    // console.log('Got Data in Search: ', data);
    return async (dispatch: Dispatch) => {
        try {
            dispatch({
                filters: filters,
                type: SearchActionTypes.SHOW_INDICATOR
            })
        } catch (err) {
            console.error('Error: ', err);
        }
    };
};

export const selectRowsAction: ActionCreator<ThunkAction<Promise<any>, ISearchState, null, ISearchAnyAction>> = (selectedRows: IKeyValuePair[]) => {
    // console.log('Got Data in Search: ', data);
    return async (dispatch: Dispatch) => {
        try {
            dispatch({
                selectedRows: selectedRows,
                type: SearchActionTypes.SELECT_ROWS
            })
        } catch (err) {
            console.error('Error: ', err);
        }
    };
};

export const resetSearchAction: ActionCreator<ThunkAction<Promise<any>, ISearchState, null, ISearchAnyAction>> = () => {
    // console.log('Got Data in Search: ', data);
    return async (dispatch: Dispatch) => {
        try {
            dispatch({
                type: SearchActionTypes.RESET_SEARCH               
            })
        } catch (err) {
            console.error('Error: ', err);
        }
    };
};

export const sortSearchAction: ActionCreator<ThunkAction<Promise<any>, ISearchState, null, ISearchAnyAction>> = (sortItem: GridSortItem[]) => {
    // console.log('Got Data in Search: ', data);
    return async (dispatch: Dispatch) => {
        try {
            dispatch({
                type: SearchActionTypes.SORT_SEARCH,
                sortItem
            })
        } catch (err) {
            console.error('Error: ', err);
        }
    };
};

export const blockUIAction: ActionCreator<ThunkAction<Promise<any>, ISearchState, null, ISearchAnyAction>> = (value: boolean) => {
    // console.log('Got Data in Search: ', data);
    return async (dispatch: Dispatch) => {
        try {
            dispatch({
                type: SearchActionTypes.BLOCK_UI,
                blockUI: value
            })
        } catch (err) {
            console.error('Error: ', err);
        }
    };
};