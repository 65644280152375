import env from 'dotenv';
import _ from 'lodash'
import React, { Fragment } from 'react'
import { Grid, Button, Typography, Container, Card, CardContent, CardHeader, CircularProgress, Menu, MenuItem, Backdrop } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import WorkOrderForm from '../components/shared/work-order-form/workOrderForm';
import { DataGrid, GridCellParams, GridColDef, GridColumns, GridRowParams, GridSortDirection, GridSortItem } from '@material-ui/data-grid';
import { useHistory } from 'react-router-dom';
import { IAppStore } from '../state/store';
import { useDispatch, useSelector } from 'react-redux';
import searchService from '../api/services/workOrderSearchService';
import { searchAction, showIndicatorAction, selectRowsAction, blockUIAction, sortSearchAction } from '../state/actions/searchAction';
import { WorkOrderFieldValue } from '../models/workOrderFieldValue';
import { ActionType, OriginationSource } from '../models/enums';
import moment from 'moment';
import { AxiosError, AxiosResponse } from 'axios';
import { Notifications } from '../components/shared/notifications/Notifications';
import { IClonedWorkOrder, updateAction } from '../state/actions/workOrderActions';
import { v4 as v4uuid } from "uuid";
import { WorkOrderPayloadResponse } from '../models/payloads/workorderPayloadResponse';
import { IWorkOrderPayloadRequest } from '../models/payloads/workorderPayloadRequest';
import service from '../api/services/genService';
import { ICreateTypes } from '../models/appDetails';
import { Constants } from '../utils/constants';
import DateSpanFilter from '../components/shared/date-span-filter/dateSpanFilter';
import { computeDateRange, DateRange } from '../components/shared/date-span-filter/dateSpanFilter.types';

env.config();

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		cardRoot: {
			minWidth: 275,
		},
		container: {
			marginTop: "30px",
			marginBottom: "20px",
			paddingLeft: "30px",
			paddingRight: "30px",
		},
		searchCardContent: {
			paddingLeft: "30px",
			paddingRight: "30px",
		},
		searchBtnContainer: {
			textAlign: "right"
		},
		searchForContainer: {
			display: "flex",
			alignItems: "flex-end"
		},
		backdrop: {
      		zIndex: theme.zIndex.drawer + 1,
    	},
		gridItem: {
			marginBottom: "1rem"
		},
		anchor: {
			textDecoration: "none",
			color: "black",
			minWidth: "115px",
    		textAlign: "center",
			width: "100%",
			textTransform: 'none'
		},
		gridPaper: {
			borderBottom: "0"
		},
		gridContainer: {
			paddingTop: "0",
			paddingLeft: "30px",
			paddingRight: "30px"
		},
		gridCard: {			
			height: "58vh",
			width: "100%",
			padding: 0,
			paddingBottom: "0 !important"
		},
		gridBtnsContainer: {
			marginBottom: "10px"
		},
		loadIndicator: {
			marginTop: "5%" 
		},
		menu: {
			marginTop: "45px"
		},
		adminBtnClone:{
			marginRight: "1rem"
		}
	}),
);

const WorkOrderListPage = () => {

	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const notifications = new Notifications(React.useRef(null));

	let columns: GridColumns = [];
	
	let { appDetails: woDetails, searchResults: search }  = useSelector((state: IAppStore) => state);	
	
	const rowsSelected = search.selectedRows.map(x => x.id) as string[];
	
	const isAdminUser = woDetails && woDetails.userActionFlags && woDetails.userActionFlags[`workorder-admin-action-id`];	
	const [dateSpan, setDateSpan] = React.useState<DateRange>(computeDateRange(isAdminUser ? 7 : 30));
	const [selectedDateSpan, setSelectedDateSpan] = React.useState<string>(isAdminUser ? 'Last 7 days' : 'Last 30 days');

	let localFieldValues = new Map<string, WorkOrderFieldValue>();

	search.filters.forEach(x => localFieldValues.set(x.name, x));		
		
	const updateFieldValues = (fieldValue: WorkOrderFieldValue) => {
		if (fieldValue.isNull) {
			localFieldValues.delete(fieldValue.name);
		} else {
			localFieldValues.set(fieldValue.name, fieldValue);
		}
	}
	
	const getRows = () => {
		let searchJsonQuery = {};

		const nonNullSearchFields = [...localFieldValues.values()].filter(wof => !wof.isNull);				
		nonNullSearchFields.forEach(wof => {
			searchJsonQuery[wof.name] = wof.getFlatValue();
		});

		const searchCriteria = nonNullSearchFields.length > 0 ? 
			searchJsonQuery : 
			{				
				...dateSpan
			};

		searchService.SearchResult.info(searchCriteria).then(s => {			
			var rows: any[] = [];
			
			s.forEach(r => {
				 let record: any = {};
				 record.id = v4uuid();
				 for(const c in r) {
					if (c === Constants.StaticFieldsNames.origin) {
						r[c] = OriginationSource[r[c]];
					}

					record[c] = r[c];
				}
	
				 rows.push(_.cloneDeep(record));
			});

			dispatch(searchAction(rows));
			dispatch(sortSearchAction(getInitialSort()));
		}).catch((err: AxiosResponse) => {
			console.log(err);
			notifications.error(err?.data || "There was a problem retrieving workorders");
		});
	}

	if (search.firstSearch) {
		getRows();
	}

	const handleOnSearch = () => {
		dispatch(showIndicatorAction([...localFieldValues.values()]));
		getRows();
	}

	const getOrigin = (originValue: any) => {
		let origin = OriginationSource.DIF;
		
		if (originValue) {
			const str: keyof typeof OriginationSource = originValue;
			origin = OriginationSource[str];
		}

		return origin;
	}

	const handleOnRowDoubleClick = (gridRow: GridRowParams, ev: any) => {
		const createType = woDetails.createTypes.find(x => x.name === gridRow.row[Constants.StaticFieldsNames.createType]);
		const origin = getOrigin(gridRow.row[Constants.StaticFieldsNames.origin]);
		
		let statusSource = "";
		const action = createType?.actions.find(x => {
			statusSource = x.statusSource || "";
			return x.statuses.some(y => y.value === gridRow.row[statusSource]) && x.originationSource === origin;
		})

		if (!action) {
			notifications.error(`There is no action definition with the status "${gridRow.row[statusSource]}"`);
			return;
		}

		const toleranceDate = moment(gridRow.row[Constants.StaticFieldsNames.createdDate]).utc().add(action.reSubmitInHs, 'hour');
		const now = moment(new Date()).utc();
		let allowResubmit = false
		
		
		if (action.reSubmit && !gridRow.row[action.reSubmitSource] && toleranceDate.isValid()) {
			allowResubmit = now.isSameOrAfter(toleranceDate);
		}


		dispatch(updateAction(gridRow.row[action?.idSource || ""], gridRow.row[statusSource], action.name, origin, allowResubmit));

		history.push({
			pathname: `/wo/${action?.name}/${createType?.name}/${gridRow.row[action?.idSource || ""]}`,
			state: {
				origin
			}
		});
	}

	if(woDetails.tableFields) {
		columns = woDetails.tableFields.map(e => {
			return { 
				field: e.name, 
				width: e.size,				
				headerName: e.label, 
				type: e.type || ["text", "multiLineText"].includes(e.type) ? "string" : e.type, 
				description: e.description ?? '',
				hide: !e.show,
				sortable: e.sortable,
				maxLength: e.maxLength,
				renderCell: (params: GridCellParams) => {
					if (e.format) {
						const date = moment(params.value as Date);
						if (date.isValid()) {
							return date.format(e.format);
						}
					}

					return params.value;
				}
				// resizable: true	--> feature not supported on free version!
			} as GridColDef;
		});
	}

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleNew = (name: string, origin: OriginationSource, cloned?: IClonedWorkOrder) => {
		dispatch(updateAction(null, "New", ActionType.Create, origin, false, cloned));
		history.push({
			pathname: `/wo/create/${name}`,
			state: {
				origin
			}
		});
	}
	
	const cloneWorkOrder = (isAdminClone: boolean) => {
		if (search.selectedRows.length === 0) {
			notifications.error("There is no workorder selected!");
			return
		}

		if (search.selectedRows.length > 1) {
			notifications.error("There is more than one workorder selected!");
			return
		}
		
		const createType = woDetails.createTypes.find(x => x.name === search.selectedRows[0][Constants.StaticFieldsNames.createType]);
		const origin = getOrigin(search.selectedRows[0][Constants.StaticFieldsNames.origin]);

		let statusSource = "";
		const action = createType?.actions.find(x => {
			statusSource = x.statusSource || "";
			return x.statuses.some(y => y.value === search.selectedRows[0][statusSource]) && x.originationSource === origin;
		})
		
		if (!action) {
			notifications.error(`There is no action definition with the status "${search.selectedRows[0][statusSource]}"`);
			return;
		}

		dispatch(blockUIAction(true));

		notifications.progress("Cloning Work Order...");
		const id = search.selectedRows[0][action?.idSource];
		const url = action?.endpoints.fetch.replace("{id}", id!);		
		
		service.dynamicRequest.get(url!).then(async result => {

			const createTypeDefinition = createType as ICreateTypes;
			const workOrderResponse = new WorkOrderPayloadResponse(id as string, result as IWorkOrderPayloadRequest, createTypeDefinition);
			
			let workOrder = await workOrderResponse.getWorkOrder();

			const cloneExceptions = isAdminClone ? createTypeDefinition.adminCloneExceptions : createTypeDefinition.cloneExceptions;
			cloneExceptions.filter(x => x.split("/").length === 1).forEach(path => {
				workOrder.delete(path);
			});
						
			const sections = await workOrderResponse.getSectionsAsNew(isAdminClone);
			console.log(workOrder, sections);

			handleNew(createType?.name!, origin, { workorder: workOrder, sections: sections, isAdminClone, workOrderSourceId: id})

			notifications.kill();

		}).catch((err: AxiosError) => {
			dispatch(blockUIAction(false));
			console.log(err)
			notifications.error(err.response?.data || "There was a problem cloning the WorkOrder");
		});
	};

	const getHandlerForClonning = (isAdminClone: boolean) => {
		return () => {
			cloneWorkOrder(isAdminClone);
		};
	};

	const getInitialSort = () => {
		let sortResult: GridSortItem[] = [];
		
		if (woDetails.tableSort) {
			sortResult.push({
				field: woDetails.tableSort.field,
				sort: woDetails.tableSort.sort as GridSortDirection
			})
		}

		return sortResult;
	}

	const getDatespanOptions = () => {
		const dateSpanOptions: string[] = isAdminUser ? 
			[
				'Last 7 days',
				'Last 15 days',
				'Last 30 days'
			] : [
				'Last 30 days',
				'Last 90 days',
				'All results'
			];
		return dateSpanOptions;
	};

	return (
		<Fragment>

			<Backdrop className={classes.backdrop} open={search.blockUI} />

			<Container className={classes.container} maxWidth={false}>
					<Grid container spacing={10} direction="row"  justify="space-between" 
					alignItems="center" >
						<Grid item className={classes.gridItem}>
							<Typography variant="h5" component="h5">{woDetails.title}</Typography>
						</Grid>
						<Grid item className={classes.gridItem}>
							<Button variant="outlined" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
								Create Work Order
							</Button>
							<Menu
								id="simple-menu"
								className={classes.menu}
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={handleClose}>
								{ woDetails.createTypes.map(x => {
									return (
										<MenuItem key={`menuItem-${x.name}`} onClick={handleClose}>
											<Button variant="text" onClick={() => handleNew(x.name, OriginationSource.DIF)} color="primary" className={classes.anchor} >
												{x.display}
											</Button>
										</MenuItem>
									)
								})}															
							</Menu>
						</Grid>
					</Grid>

					<Card className={classes.cardRoot}>
						<CardHeader 
						subheader={
							<Fragment>
								<Grid container spacing={3} justify="space-between">
									<Grid item xs={3} className={classes.searchForContainer}>
										<strong>Search for:</strong>
										<DateSpanFilter options={getDatespanOptions()} 
											selectedOption={selectedDateSpan}
											setSelectedOption={setSelectedDateSpan}
											setDatespan={setDateSpan} />
									</Grid>
									<Grid item xs={2} className={classes.searchBtnContainer}>
										<Button key="searchButton"
											onClick={e => handleOnSearch()}
											variant="outlined" color="primary">Show Results</Button>
									</Grid>
								</Grid>
							</Fragment>
						}/>
						<CardContent className={classes.searchCardContent}>
							<WorkOrderForm 
								fields={woDetails.searchFields}
								gridSize={3}
								initialfieldValues={localFieldValues}
								setParentFieldValues={updateFieldValues}
							/>
						</CardContent>
					</Card>
				</Container>

			{
				!search.firstSearch && !search.isSearching ?
					<Container className={classes.gridContainer} maxWidth={false}>
						<Grid container justify="flex-end" className={classes.gridBtnsContainer}>
							{
								isAdminUser &&
								(
									<Grid item className={classes.adminBtnClone}>
										<Button variant="outlined" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={getHandlerForClonning(true)}>
											Admin Clone
										</Button>
									</Grid>
								)
							}
							<Grid item>								
								<Button variant="outlined" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={getHandlerForClonning(false)}>
									Clone
								</Button>
							</Grid>							
						</Grid>
						<Card className={classes.cardRoot}>
							<CardContent className={classes.gridCard}>								
								<DataGrid rows={search?.rows} columns={columns} 
									pageSize={20}								
									checkboxSelection
									sortModel={search.sortModel}
									onSortModelChange={(model) => dispatch(sortSearchAction(model.sortModel))}
									onRowDoubleClick={handleOnRowDoubleClick}
									onSelectionModelChange={(e) => {
										const selectedIDs = new Set(e.selectionModel);
										const rowsSelected = search?.rows.filter(x => selectedIDs.has(x.id))
										dispatch(selectRowsAction(rowsSelected))
									}}
									selectionModel={rowsSelected}									
								/>
							</CardContent>
						</Card>
					</Container>
				: <Fragment>
						<Grid container spacing={3} justify="center" className={classes.loadIndicator}>
							<Grid item>
								<CircularProgress color="inherit" />
							</Grid>
						</Grid>
					</Fragment>
			}

		</Fragment>
	);
};

export default WorkOrderListPage;