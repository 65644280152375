import { IFieldOption } from './../appDetails';

export interface IFieldAssociationRequest {
  workOrderType: string;
  field: string;
  value: string;
  parent?: IFieldAssociationParent
} 

export class FieldAssociationRequest implements IFieldAssociationRequest {
  workOrderType: string;
  field: string;
  value: any;
  parent?: IFieldAssociationParent

  constructor(workOrderType: string, name: string, value: any, parent?: IFieldAssociationParent) {
    this.workOrderType = workOrderType;
    this.field = name;
    this.value = value;
    this.parent = parent;
    
  }
}

export interface IFieldAssociationParent {
  field: string;
  value: string;
  workOrderType: string;
  parent?: IFieldAssociationParent
}

export interface IFieldAssociationResponse {
  workOrderType: string;
  results: IFieldOptionsResults[];
}

export interface IFieldOptionsResults {
  field: string;
  values: IFieldOption[]
}