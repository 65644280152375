export enum FieldType {
  Text = "text",
  Number = "number",
  MultiLineText = "multiLineText",
  SingleSelect = "singleSelect",
  MultiSelect = "multiSelect",
  Date = "dateSelect",
  TypeAhead = "typeAhead",
  Attachment = "attachment"
}

export enum ActionType {
  Create = "create",
  View = "view",
  Edit = "edit"
}

export enum OperatorType {
  Equals = "=",
  NotEquals = "!=",
  GreaterThan = ">",
  NotGreaterThan = "!>",
  GreaterOrEqualsThan = ">=",
  NotGreaterOrEqualsThan = "!>=",
  LessThan = "<",
  LessOrEqualsThan = "<=",
  NotLessThan = "!<",
  NotLessOrEqualsThan = "!<="
}

export enum ValidationType {
  Field = "field",
  Value = "value",
}

export enum OriginationSource {
  DIF,
  Upstream,
  Api
}