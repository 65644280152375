import request from '../service';
import axios, { CancelTokenSource } from "axios";
import { ISearchResult } from '../../models/searchResult';

const SearchResult = {
    info: (searchJsonQuery: any = {}): Promise<ISearchResult[]> =>
    request.post(`/search`, searchJsonQuery)
};

export default {
	SearchResult
};