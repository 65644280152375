import { IFieldOption, ISortOptions } from '../models/appDetails';

export default function sortByRules(options: IFieldOption[], rules: ISortOptions) {
  if (["asc", "desc"].includes(rules?.order || "")) {
    options.sort((o1, o2) => {
      var value1 = o1[rules?.property!].toLowerCase(); // ignore upper and lowercase
      var value2 = o2[rules?.property!].toLowerCase(); // ignore upper and lowercase
      
      if (value1 < value2) return -1;
      if (value1 > value2) return 1;

      return 0;
    })

    if (rules?.order === "desc") options.reverse();
  }

  if (rules?.firstOptions.length! > 0) {
    let firstOptions: IFieldOption[] = [];
    rules?.firstOptions.forEach(fo => {
      const index = options.findIndex(x => x[rules?.property!] == fo);
      if (index > -1) {
        firstOptions.push(options.splice(index, 1)[0]);
      }
    })

    options = [...firstOptions, ...options];
  }

  if (rules?.lastOptions.length! > 0) {
    let lastOptions: IFieldOption[] = [];
    rules?.lastOptions.forEach(fo => {
      const index = options.findIndex(x => x[rules?.property!] == fo);
      if (index > -1) {
        lastOptions.push(options.splice(index, 1)[0]);
      }
    })

    options = [...options, ...lastOptions];
  }

  return options
}