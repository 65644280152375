import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import React, { useEffect, Fragment } from "react";
import { IFieldOption, IFileFieldOption, IPageField } from "../../../../../models/appDetails";
import { WorkOrderFieldValue } from "../../../../../models/workOrderFieldValue";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

interface Props {
  open: boolean,
  field: IPageField,
  files: IFileFieldOption[],
  closeDialog: (changes?: IFileFieldOption[]) => void
}

const AttachmentsEditor: React.FC<Props> = ({
  open,
  field,
  files,
  closeDialog
}) => {
  const [stateFiles, setFiles] = React.useState<IFileFieldOption[]>(files)
  const key = `${field.guid}-${field.name}-editor`;

  const handleOnClose = () => {
    closeDialog()
  }

  const saveChanges = () => {
    closeDialog(stateFiles);
  }

  const onDeleteClick = (index: number) => {
    stateFiles.splice(index, 1)
    setFiles([...stateFiles]);
  }

  const renderDeleteBtn = (value: boolean, index: number) => {
    if (value) {
      return (
        <IconButton component="span" edge="end" size="small" onClick={() => onDeleteClick(index)}>
          <HighlightOffIcon />
        </IconButton> 
      )
    }

    return null;
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true} onClose={() => handleOnClose()} aria-labelledby={key}>
      <DialogTitle key={key} id={key}>
        <Grid container justify="space-between">
          <Grid item>
            Remove Attachments
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <List>
          {stateFiles.map((file, i) => (
            <Fragment key={`litem-${i}`}>
              <ListItem>
                <ListItemText primary={file.label} />
                <ListItemSecondaryAction>
                  {renderDeleteBtn(file.new, i)}
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} color="primary">
          Close
        </Button>
        <Button onClick={saveChanges} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AttachmentsEditor;