import { OriginationSource } from './../../models/enums';
import { IWorkOrderAction, WorkOrderActionTypes, IClonedWorkOrder } from './../actions/workOrderActions';
import { Reducer } from 'redux';
import { ActionType } from '../../models/enums';

export interface IWorkOrderState {
  id?: string;
  status?: string;
  action?: ActionType;
  origin?: OriginationSource,
  cloned?: IClonedWorkOrder,
  allowResubmit?: boolean
}

const initialWorkOrderState: IWorkOrderState = {
  
}

export const workOrderReducer: Reducer<IWorkOrderState, IWorkOrderAction> = (state = initialWorkOrderState, action) => {
  switch (action.type) {
    case WorkOrderActionTypes.UPDATE_WORKORDER:     
      return {
        ...state,
        id: action.input.id,
        status: action.input.status,
        action: action.input.action,
        origin: action.input.origin,
        cloned: action.input.cloned,
        allowResubmit: action.input.allowResubmit
      }
    case WorkOrderActionTypes.RESET_WORKORDER:     
      return {
        ...state,
        id: undefined,
        status: undefined,
        action: undefined,
        origin: undefined,
        cloned: undefined,
        allowResubmit: undefined
      }
    default:
      return {
        ...state
      }
  }
};