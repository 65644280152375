import { Button, Grid, IconButton } from "@material-ui/core";
import React, { Fragment } from "react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { IActionStatusFormFields, ISection } from "../../models/appDetails";
import { ISectionForm, WorkOrderFieldValue } from "../../models/workOrderFieldValue";
import WorkOrderForm from "../shared/work-order-form/workOrderForm";
import { v4 as uuidv4 } from "uuid";
import { IFieldsValidationResult } from "../../models/workOrder";
import { IWorkOrderSection } from "../../models/workOrderSection";

interface IProps {
  mainValues?: Map<string, WorkOrderFieldValue>,
  sectionValues?: Map<string, WorkOrderFieldValue>,
  subSection: ISection;
  subSectionValues: ISectionForm[];
  actionFormFields?: IActionStatusFormFields;
  setSubSectionValues: (subSection: ISectionForm[]) => void;
  setSectionValidationDispatcher: (validateSections: () => IFieldsValidationResult[], additionalValidation: () => IFieldsValidationResult[]) => void,
  setTriggerDropdownsChange?: (dropdownTrigger: () => void) => void
}

const useStyles = makeStyles((theme: Theme) => 
createStyles({
  formGroup: {
    marginBottom: 30
  },
  formGrid: {
    paddingRight: 40,
  }
})
)

const WorkOrderSubSectionEditor: React.FC<IProps> = ({
  mainValues,
  sectionValues,
  subSection,
  subSectionValues,
  actionFormFields,
  setSubSectionValues,
  setSectionValidationDispatcher,
  setTriggerDropdownsChange
}) => {
  const classes = useStyles();
  const [sectionsList, setSectionsList] = React.useState<ISectionForm[]>(subSectionValues);
  let requiredValidationDispatchers: Map<string, () => IFieldsValidationResult> = new Map();
  let additionalValidationDispatchers: Map<string, () => IFieldsValidationResult> = new Map();

  const setSectionFieldValue = (fieldValue: WorkOrderFieldValue, formKey?: string) => {
    console.log({values:fieldValue, formKey: formKey });
    const sectionIndex = sectionsList.findIndex(x => x.guid == formKey);
    sectionsList[sectionIndex].fieldValues.set(fieldValue.name, fieldValue);

    setSubSectionValues([...sectionsList]);
  }



  const addNewSubSectionForm = () => {
    const fieldValues: Map<string, WorkOrderFieldValue> = new Map();
    WorkOrderFieldValue.preSetFields(subSection.createFields, fieldValues);

    if (subSection.submitProperties.field) {
      const field = subSection.createFields.find(x => x.name === subSection.submitProperties.field);
      if (field) {
        fieldValues.set(field.name, new WorkOrderFieldValue(field, subSection.submitProperties.create));
      }
    }

    sectionsList.push({ guid: uuidv4(), name: subSection.name, fieldValues: fieldValues, deleted: false });
    setSectionsList([...sectionsList])
    setSubSectionValues([...sectionsList]);
  }

  const deleteSubSectionForm = (guid: string) => {
    const sectionIndex = sectionsList.findIndex(x => x.guid == guid);
    

    if (subSection.submitProperties.field) {
      const field = subSection.createFields.find(x => x.name === subSection.submitProperties.field);
      const fieldValue = sectionsList[sectionIndex].fieldValues.get(subSection.submitProperties.field);
      if (field) {
        if (fieldValue?.textValue === subSection.submitProperties.edit) {
          sectionsList[sectionIndex].fieldValues.set(field.name, new WorkOrderFieldValue(field, subSection.submitProperties.delete));
          sectionsList[sectionIndex].deleted = true;
        } else {
          sectionsList.splice(sectionIndex, 1);
        }
      }
    }

    setSectionsList([...sectionsList])
    setSubSectionValues([...sectionsList]);
  }

  const setValidationDispatcher = (formKey: string, requiredDispatcher: () => IFieldsValidationResult, additionalValidationDispatcher: () => IFieldsValidationResult) => {
		requiredValidationDispatchers.set(formKey, requiredDispatcher);
		additionalValidationDispatchers.set(formKey, additionalValidationDispatcher);
	}

  const validateForms = () => {
    let result: IFieldsValidationResult[] = [];
    for (const section of sectionsList) {
      const requiredDispatch = requiredValidationDispatchers.get(section.guid);     
      if (requiredDispatch) result.push(requiredDispatch());
    }

    return result; 
  }

  const additionalValidation = () => {
    let result: IFieldsValidationResult[] = [];

    for (const section of sectionsList) {
      const additionalDispatch = additionalValidationDispatchers.get(section.guid);
      if (additionalDispatch) result.push(additionalDispatch());
    }

    return result; 
  }

  const renderOnMainDropdownsChange = () => {
    sectionsList.forEach(x => x.dropDownsTriggered = false);
    setSectionsList([...sectionsList]);
  }

  if (setSectionValidationDispatcher) setSectionValidationDispatcher(validateForms, additionalValidation);
  if (setTriggerDropdownsChange) setTriggerDropdownsChange(renderOnMainDropdownsChange);

  const renderAddBtn = () => {
    if (!actionFormFields || actionFormFields?.isNew || actionFormFields?.addSection) {
			return (
				<Button variant="outlined" color="primary" onClick={addNewSubSectionForm}>
          Add {subSection.display}
        </Button>
			)
		}

		return null;
  }

  let triggerDropdownChange = new Map<string, (fieldValue: WorkOrderFieldValue, justUpdateMap: boolean) => void>();

  const attachDropdownChange = (formDropdownChange: (fieldValue: WorkOrderFieldValue, justUpdateMap: boolean,) => void, formId?: string) => {
    triggerDropdownChange.set(formId!, formDropdownChange);
  }

  const renderDeleteBtn = (guid: string) => {
    if (!actionFormFields || actionFormFields?.isNew || actionFormFields?.addSection) {
			return (
				<IconButton component="span" onClick={() => deleteSubSectionForm(guid)}>
          <HighlightOffIcon />
        </IconButton>
			)
		}

		return null;
  }

  const renderForm = (sectionForm: ISectionForm) => {

    if (mainValues || sectionValues) {
      let fieldsToTrigger: WorkOrderFieldValue[] = [];
      subSection.createFields.forEach(x => {
        let fieldValue = sectionForm.fieldValues.get(x.name);
        if (x.prePopulate) {
          const splited = x.prePopulate.split(`/`)
          let mainValue: WorkOrderFieldValue | undefined;
          if (splited.length == 2) {
            mainValue = mainValues?.get(splited[1]);            
          } 
          
          if (splited.length == 3) {
            mainValue = sectionValues?.get(splited[2]); 
          }

          if (!fieldValue || fieldValue.isNull) {
            fieldValue = new WorkOrderFieldValue(x, mainValue?.getFieldValue());
            sectionForm.fieldValues.set(x.name, fieldValue);
            if (fieldValue.hasDependencies) {
              fieldsToTrigger.push(fieldValue);
            }
            return
          }

          if (!x.show) {
            if (mainValue && !mainValue.isNull && mainValue.getFlatValue() != fieldValue?.getFlatValue()) {
              fieldValue = new WorkOrderFieldValue(x, mainValue?.getFieldValue());
              sectionForm.fieldValues.set(x.name, fieldValue);
              if (fieldValue.hasDependencies) {
                fieldsToTrigger.push(fieldValue);
              }
              return
            }
  
            if ((!mainValue || mainValue.isNull) && fieldValue) {
              fieldValue = new WorkOrderFieldValue(x, mainValue?.getFieldValue());
              sectionForm.fieldValues.set(x.name, fieldValue);
              if (fieldValue.hasDependencies) {
                fieldsToTrigger.push(fieldValue);
              }
              return
            }
  
          }
        }
      })
  
      setTimeout(() => {
        fieldsToTrigger.forEach(fieldValue => {
          const trigger = triggerDropdownChange.get(sectionForm.guid);
          if (trigger && !sectionForm.dropDownsTriggered) {
            trigger(fieldValue, false)
          }
        })
        sectionForm.dropDownsTriggered = true;
      }, 500);
    }

    return (
      <WorkOrderForm
        key={`form-${sectionForm.guid}`}
        workOrderType={subSection.name}
        formKey={sectionForm.guid}
        // gridSize={3}
        fields={subSection.createFields || []}
        actionFormFields={actionFormFields}
        initialfieldValues={sectionForm.fieldValues}
        setParentFieldValues={setSectionFieldValue}
        setValidationDispatcher={setValidationDispatcher}
        triggerDropdownChange={attachDropdownChange}
      />
    )
  }

  return (
    <Fragment>
      <Grid container className={classes.formGroup}>
        <Grid item>
          {renderAddBtn()}
        </Grid>
      </Grid>
      {sectionsList.filter(x => !x.deleted).map(x => {
        return (
          
          <div key={`div-form-${x.guid}`} className={classes.formGroup}>
            <Grid container spacing={3} className={classes.formGrid}>
              <Grid item>
                {renderDeleteBtn(x.guid)}
              </Grid>
              <Grid item xs>
                {renderForm(x)}
              </Grid>
            </Grid>
          </div>
        )
      })}
    </Fragment>
  )
}

export default WorkOrderSubSectionEditor;