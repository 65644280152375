import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useWorkOrderStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		margin: {
			margin: theme.spacing(1),
		},
		withoutLabel: {
			marginTop: theme.spacing(3),
		},
		textField: {
			width: '25ch',
		},
		dateField: {
			marginTop: "0 !important",
		},
		values: {
			showPW: false
		},
		centerButton: {
			display: 'flex',
			'justify-content': 'center',
			'align-items': 'center',
		},
		customMuiChip: {
			height: "24px",
		},
    	hidden: {
      		display: "none"
    	},
		clearBtn: {
			padding: 0
		},
		selectLoadingIndicator: {
			position: "absolute", 
			right: "25px"
		},
		cursorPointer: {
			cursor: "pointer",
		},
		attachmentFieldPointer: {
			"& input": {
				cursor: "pointer !important"
			}
		},
		attachmentField: {
			"& input": {
				color: "black !important",
			},
			"& label":{
				color: "rgba(0, 0, 0, 0.54) !important",
			},
			"& svg":{
				color: "rgba(0, 0, 0, 0.54) !important",
			},
			"& span":{
				color: "rgba(0, 0, 0, 0.54) !important",
			}
		},
		disabledAttachmentField: {
			"& .Mui-disabled": {
				color: "rgba(0, 0, 0, 0.38) !important",
    			cursor: "default !important",
			},
			"& svg":{
				cursor: "default !important",
			},
			"& span":{
				cursor: "default !important",
			}
		},
		attachmentIcon: {
			marginLeft: "0.2rem",
			transform: "rotate(90deg)"
		},
		searchDateSpanFilter:{
			paddingLeft: "20px"
		}
	}),
);

export default useWorkOrderStyles;