import { WorkOrderSection } from './../workOrderSection';
import { ICreateTypes, IFieldOption, IFileFieldOption, ISection, ISubSection } from './../appDetails';
import { IPageField } from '../appDetails';
import { IWorkOrderSection } from '../workOrderSection';
import { ISectionForm, WorkOrderFieldValue } from '../workOrderFieldValue';
import { FieldType } from '../enums';
import moment from "moment";
import { FieldAssociationRequest, IFieldAssociationParent, IFieldOptionsResults } from './fieldAssociationPayload';
import dropdownService from '../../api/services/dropdownService';
import { v4 as uuidv4 } from "uuid";
import { IWorkOrderPayloadRequest, IFieldPayload, ISectionPayloadRequest } from './workorderPayloadRequest';
import sortByRules from "../../utils/dropdown-helper";

export interface IWorkOrderPayloadResponse {
  id?: string;
  createType: string;
  fields: IKeyValuePair,
  section: ISectionPayloadResponse[]
}

export interface ISectionPayloadResponse {
  name: string;
  fields: IFieldPayload[];
  section: ISectionPayloadResponse[]
}

export interface IKeyValuePair {
  [key: string]: any
}

export class WorkOrderPayloadResponse implements IWorkOrderPayloadResponse {
  id?: string;
  createType: string;
  fields: IFieldPayload[];
  section: ISectionPayloadRequest[];
  private createTypeDef: ICreateTypes;

  constructor(id: string, response: IWorkOrderPayloadRequest, createType: ICreateTypes) {
    this.id = id;
    this.createType = response.name;
    this.fields = response.fields;
    this.section = response.section;
    this.createTypeDef = createType;
  }

  getWorkOrder = async () => {
    let responseFields = this.setSubmitProperties(this.createTypeDef, this.fields);
    let workOrder = await this.getWorkOrderFieldValues(this.createTypeDef, responseFields);
    
    return workOrder;
  }

  getSections = async (): Promise<IWorkOrderSection[]> => {
    let sections: IWorkOrderSection[] = [];
    
    for (const sec of this.section) {
      sections.push(
        WorkOrderSection.createWorkOrderSection(
          null,
          sec.name,
          await this.getWorkOrderFieldValues(
            this.createTypeDef?.section as ISection, 
            this.setSubmitProperties(this.createTypeDef?.section as ISection,sec.fields)),
          await this.getSubSections(sec.section)
        )
      )
    }

    return sections;
  }

  getSectionsAsNew = async (isAdminClone: boolean = false): Promise<IWorkOrderSection[]> => {
    let sections: IWorkOrderSection[] = [];

    sections = await this.getSections();

    const cloneExceptions = isAdminClone ? this.createTypeDef.adminCloneExceptions : this.createTypeDef.cloneExceptions;

    sections.forEach(sectionRow => {
      cloneExceptions.filter(x => x.split("/").length === 3).forEach(path => {
        const index = path.lastIndexOf("/");
        sectionRow.section.delete(path.substring(index+1))
      });

      if (this.createTypeDef.section?.submitProperties.field) {
        const secField = this.createTypeDef.section?.createFields.find(x => x.name === this.createTypeDef.section?.submitProperties.field);
        if (secField) {
          sectionRow.section.set(secField.name, new WorkOrderFieldValue(secField, this.createTypeDef.section?.submitProperties.create));
        }
      }

      sectionRow.subSections.forEach(subSectionRow => {
        cloneExceptions.filter(x => x.split("/").length === 4).forEach(path => {
          const index = path.lastIndexOf("/");
          subSectionRow.fieldValues.delete(path.substring(index+1))
        });

        if (this.createTypeDef.section?.section?.submitProperties.field) {
          const subSecField = this.createTypeDef.section?.section?.createFields.find(x => x.name === this.createTypeDef.section?.section?.submitProperties.field);
          if (subSecField) {
            subSectionRow.fieldValues.set(subSecField.name, new WorkOrderFieldValue(subSecField, this.createTypeDef.section?.section?.submitProperties.create));
          }
        }

        WorkOrderFieldValue.preSetFields(this.createTypeDef.section?.section?.createFields, subSectionRow.fieldValues);
      });
    });
    
    return sections;
  }  

  private setSubmitProperties = (createType: ICreateTypes | ISection | ISubSection, fieldsPayload: IFieldPayload[]) => {
    const field = createType?.createFields.find(x => x.name === createType?.submitProperties?.field)
    
    if (field) {
      let index = fieldsPayload.findIndex(x => x.name === field.name);
      if (index > -1) fieldsPayload[index].value = createType.submitProperties.edit as string;
    }

    return fieldsPayload;
  }

  private getWorkOrderFieldValues = async (createTypeDef: ICreateTypes | ISection | ISubSection, responseFields: IFieldPayload[]) => {
    let fieldValues = new Map<string, WorkOrderFieldValue>();
    let fieldOptions: IFieldOptionsResults[] = [] 

    for (const responseField of responseFields) {
      const field = createTypeDef.createFields.find(x => x.name === responseField.name);
      const childField = createTypeDef.createFields.find(x => x.name === field?.dependencies?.child);
      let value: string | IFieldOption | IFieldOption[] | IFileFieldOption[] | Date | null | undefined;
      let zipFile: IFieldOption | undefined;
      let initialOptions: IFieldOption[] = [];

      switch (field?.type) {
        case FieldType.Date:
          if (responseField.value)
            value = moment(responseField.value as string).startOf("day")?.toDate();		
          break;
        case FieldType.Attachment:
          if (responseField.value)
            value = await WorkOrderFieldValue.unzipAsync(responseField.value as IFieldOption);
            zipFile = responseField.value as IFieldOption;
          break;       
        default:
          value = responseField.value;
          break;
      }

      if (childField && responseField.value) {
        const parentAssociation = this.getDropdownDependency(createTypeDef, field as IPageField, responseFields);

        const dropdownPayload = new FieldAssociationRequest(
          createTypeDef?.name as string, 
          responseField.name, 
          (responseField.value as IFieldOption).value, 
          parentAssociation);          

          fieldOptions = [
            ...fieldOptions, 
            ...await dropdownService
                .getOptions(dropdownPayload)
                .then(res => {
                  return res.results
                }).catch(err => {
                  return [];
                })
          ];						
      }

      if (fieldOptions.length > 0) {        
        fieldOptions.forEach(fo => {
          const fieldDef = createTypeDef.createFields.find(x => x.name === fo.field)
          if (fieldDef?.sortOptions) {
            fo.values = sortByRules(fo.values, fieldDef.sortOptions)
          }
        })
        
        const fieldOption = fieldOptions.find(x => x.field === responseField.name);
        if (fieldOption) {
          initialOptions = fieldOption.values;
        }
      }

      const fieldValue = new WorkOrderFieldValue(field, value)
        .addInitialOptions(initialOptions);

      fieldValue.zipAttachment = zipFile;
      
      fieldValues.set(responseField.name, fieldValue)
    }

    return fieldValues;
  }

  private getDropdownDependency = (createTypeDef: ICreateTypes | ISection | ISubSection, currentField: IPageField, fieldPayload: IFieldPayload[]) => {
		const parentField = createTypeDef.createFields.find(x => x.name === currentField?.dependencies?.parent);

    if (parentField) {
      const parentResponseField = fieldPayload.find(x => x.name === parentField.name);
      let parentAssociation: IFieldAssociationParent = {
        field: parentField.name,
        value: (parentResponseField?.value as IFieldOption)?.value,
        workOrderType: createTypeDef.name as string,
        parent: this.getDropdownDependency(createTypeDef, parentField, fieldPayload)
      }

      return parentAssociation;
    }
	}

  private getSubSections = async (sections: ISectionPayloadRequest[] = []) => {
    let subSections: ISectionForm[] = [];

    for (const sec of sections) {
      subSections.push({
        guid: uuidv4(),
        name: sec.name,
        deleted: false,
        fieldValues: await this.getWorkOrderFieldValues(
          this.createTypeDef?.section?.section as ISubSection, 
          this.setSubmitProperties(this.createTypeDef?.section?.section as ISubSection, sec.fields))
      })
    }
    return subSections;
  }
}