import _ from 'lodash'
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IAppState } from '../reducers/appReducer'

export enum AppActionTypes {
	APP_GET = 'APP_GET',
	DENY_ACCESS = 'DENY_ACCESS',
	RESET = 'RESET',
	UPDATE_TOKEN = 'UPDATE_TOKEN'
}

export interface IAppSetAction {
	type: AppActionTypes;
	input: any | {}
}

export interface IAppGetAction {
	type: AppActionTypes;
	appDetails: IAppState
	token: string;
	userActionFlags: any;
}

export type AppActions = IAppGetAction;

export const getAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IAppGetAction>> = (app: any, token: string, userActionFlags: any) => {
	// console.log('Got Data in App: ', data);
	return async (dispatch: Dispatch) => {
		try {
			// Your logic here
			dispatch({
				appDetails: app,
				token: token,
				userActionFlags: userActionFlags,
				type: AppActionTypes.APP_GET
			})
		} catch (err) {
			console.error(err);
		}
	};
};

export const denyAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IAppGetAction>> = () => {
	// console.log('Got Data in App: ', data);
	return async (dispatch: Dispatch) => {
		try {
			// Your logic here
			dispatch({
				type: AppActionTypes.DENY_ACCESS
			})
		} catch (err) {
			console.error(err);
		}
	};
};

export const resetAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IAppGetAction>> = () => {
	// console.log('Got Data in App: ', data);
	return async (dispatch: Dispatch) => {
		try {
			// Your logic here
			dispatch({
				type: AppActionTypes.RESET
			})
		} catch (err) {
			console.error(err);
		}
	};
};

export const updateTokenAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IAppGetAction>> = (token: string) => {
	// console.log('Got Data in App: ', data);
	return async (dispatch: Dispatch) => {
		try {
			// Your logic here
			dispatch({
				type: AppActionTypes.UPDATE_TOKEN,
				token
			})
		} catch (err) {
			console.error(err);
		}
	};
};