export class Constants {

    public static StaticFieldsNames = {
        createType: "createType",
        createdDate: "createdDate",
        origin: "originationSource",
    }

    public static workOrderMessages = {
        saveInProgress: "Saving Work Order...",
        saveSuccess: "Work Order saved!",
        reSubmitInProgress: "Re-Submitting Work Order...",
        resubmitSuccess: "Work Order Re-submitted!",
        cancelInProgress: "Requesting deletion/cancellation...",
        cancelSuccess: "Request for deletion/cancellation sent.",
        multipleSaveInProgress: "Saving Work orders...",
        multipleSaveSuccess: "Work Orders Saved!",
        multipleSaveValidating: "Validating..."
    }

    public static titleCheckMessages = {
        titleFieldNotSet: "Title field name for multiTitle is not set in appDetails",
        titleFieldValueNotSet: "title field value for multiTitle is not set in the page properly",
        requestGenericError: "There was an error checking the title for multiple submits!",        
    }
}