import request from '../service';
import axios, { CancelTokenSource } from "axios";
import { IAppDetailsPayload } from '../../models/payloads/appDetailsPayload';

const AppDetails = {
    info: (): Promise<IAppDetailsPayload> =>
    request.get(`/details?url=${window.location.protocol}//${window.location.host}`)
};

export default {
	AppDetails
}