import { Button, Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from "react";
import { IFieldOption } from "../../models/appDetails";
import { Notifications } from "../shared/notifications/Notifications";
import useBulkSharedStyles from "./bulk-sharedStyles";
import BulkTitleSearch from "./bulk-titleSearch";
import BulkTitlesList from "./bulk-titlesList";

export interface IProps {
  mainTitleCpmProductId: number | undefined;
  open: boolean;
  onClose: () => void;
  onSave: (selectedTitles: IFieldOption[]) => void
}

const BulkWorkorderDialog: React.FC<IProps> = ({
  mainTitleCpmProductId,
  open,
  onClose,
  onSave
}) => {
  const [titles, setTitles] = useState<IFieldOption[]>([]);
  const [selectedTitles, setSelectedTitles] = useState<IFieldOption[]>([]);
  
  const classes = useBulkSharedStyles();
  const notifications = new Notifications(React.useRef(null));

  const visibleUnselectdTitles = titles.filter(t => selectedTitles.every(st => st.value !== t.value))

  const validateTitles = () => {
    if (selectedTitles.length === 0) {
      notifications.error(`Selected titles are required for a bulk save`);
      return false;
    }

    return true
  }

  const handleOnClose = (isSaveAction?: boolean) => {
    if (isSaveAction) {
      validateTitles() && onSave(selectedTitles);
    } else {
      setTitles([]);
      setSelectedTitles([]);
  
      onClose();      
    }

  };

  const handleOnTitleSearch = (titles: IFieldOption[]) => {
    const filteredTitles = titles.filter(x => parseInt(x.value) !== mainTitleCpmProductId)
    setTitles(filteredTitles);
  }

  const handleOnTitleSelect = (title: IFieldOption, index: number) => {
    selectedTitles.push(title);
    setSelectedTitles([...selectedTitles]);
  }

  const handleOnTitleRemove = (title: IFieldOption, index: number) => {
    selectedTitles.splice(index, 1);
    setSelectedTitles([...selectedTitles]);
  }

  return (
    <Dialog open={open} classes={{ paper: classes.dialogPaper }} maxWidth="lg" fullWidth={true} onClose={() => handleOnClose()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        <Grid container justify="space-between" className={classes.dialogGridContainer}>
          <Grid item>
            Bulk Apply Work Order
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => handleOnClose()}>
              Close
            </Button>
            &nbsp; &nbsp;
            <Button variant="outlined" color="primary" onClick={(e) => handleOnClose(true)}>
              Save
            </Button>
          </Grid>
        </Grid>

      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs>
            <BulkTitleSearch onSearch={handleOnTitleSearch} />
          </Grid>
          <Grid item xs spacing={0}>
            <span className={classes.selectionsTitle}>Selections:</span>            
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs>
            <BulkTitlesList 
              icon="add" 
              titles={visibleUnselectdTitles} 
              onItemClick={(title, index) => handleOnTitleSelect(title, index)} />
          </Grid>
          <Grid item xs>
            <BulkTitlesList 
              icon="remove" 
              titles={selectedTitles} 
              onItemClick={(title, index) => handleOnTitleRemove(title, index)} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default BulkWorkorderDialog;