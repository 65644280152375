import React, { Component, FC, Fragment } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { LocalStorageHelper } from "../../utils/localStorage-helper";

type PrivateRouteProps = {
  loginPath: string;
} & RouteProps

const PrivateRoute: FC<PrivateRouteProps> = ({loginPath, children, ...rest}) => {
  const isAuthenticated = LocalStorageHelper.hasToken();

  return  (
    <Route 
      {...rest}
      render={({ location }) => {
        if (isAuthenticated) 
          return children;

        return (
          <Redirect to={{
              pathname: loginPath,
              state: { from: location }
            }}
          />
        );
      }

      }
    />
  )
};

export default PrivateRoute;