import { IAttachment, IAttachmentField, IDependency, IFieldOption, IFileFieldOption, IPageField } from './appDetails';
import { FieldType, OperatorType, ValidationType } from "./enums";
import moment from "moment";
import JSZip from "jszip";

export class WorkOrderFieldValue {
  name: string;
  type: FieldType;
  field?: IPageField;
  dynamicValue: string | IFieldOption | IFieldOption[] | IFileFieldOption[] | Date | null | undefined;
  textValue?: string | null;
  numberValue?: number | null;
  selectValue?: IFieldOption | null;
  multipleValue?: IFieldOption[] | null;
  dateValue?: Date | null;
  zipAttachment?: IFieldOption;
  attachments?: IFileFieldOption[];
  dependencies?: IDependency;
  hasDependencies: boolean;
  isNull: boolean = false;
  isRequired: boolean = false;
  isDirty: boolean = false;
  targetField?: string;
  initialOptions: IFieldOption[]

  constructor(field: IPageField | undefined, value: string | IFieldOption | IFieldOption[] | IFileFieldOption[] | Date | null | undefined, comeFromInput: boolean = false) {
    this.field = field;
    this.name = field?.name as string;
    this.type = field?.type as FieldType;
    this.targetField = field?.targetField;
    this.isRequired = field?.required ?? false;
    this.dynamicValue = value;
    this.initialOptions = [];
    this.hasDependencies = (field?.dependencies && field?.dependencies.child) ? true : false;
    this.dependencies = field?.dependencies;
    this.isDirty = comeFromInput;
    this.assignValue();
  }

  addInitialOptions = (options: IFieldOption[] = []) => {
    this.initialOptions = options;
    return this;
  }

  private assignValue = async () => {
    switch (this.type) {
      case FieldType.Text:
      case FieldType.MultiLineText:
        this.isNull = this.dynamicValue as string ? false : true;
        this.textValue = !this.isNull ? this.dynamicValue as string : undefined;
        break;
      case FieldType.Number:
        this.isNull = this.dynamicValue != null && (this.dynamicValue as string).length > 0 ? false : true;
        this.textValue = !this.isNull ? this.dynamicValue as string : undefined;
        break;
      case FieldType.SingleSelect:
      case FieldType.TypeAhead:
        this.isNull = (this.dynamicValue as IFieldOption)?.value ? false : true;
        this.selectValue = !this.isNull ? this.dynamicValue as IFieldOption : undefined;
        break;
      case FieldType.MultiSelect:
        this.isNull = (this.dynamicValue as IFieldOption[])?.length > 0 ? false : true;
        this.multipleValue = !this.isNull ? this.dynamicValue as IFieldOption[] : undefined;
        break;
      case FieldType.Date:
        this.isNull = this.dynamicValue as Date ? false : true;
        this.dateValue = !this.isNull ? this.dynamicValue as Date : undefined;
        break;
      case FieldType.Attachment:
        this.isNull = (this.dynamicValue as IFileFieldOption[])?.length > 0 ? false : true;       
        this.attachments = !this.isNull ? this.dynamicValue as IFileFieldOption[] : undefined;
        break;
      default:
        break;
    }
  }

  static unzipAsync = async (zipFile: IFieldOption) => {
    let files: IFileFieldOption[] = [];
    var zip = await new JSZip().loadAsync(zipFile.value, { base64: true });

    for (const key of Object.keys(zip.files)) {
      files.push({
        label: key,
        value: await zip.file(key)?.async('base64') as string,
        new: false
      })
    }

    return files
  }

  withZipValue = async () => {
    let zip = new JSZip();
    let label = "";
    let zipAttachment: IFieldOption | undefined;
    const files = this.attachments || [];
    
    if (files.length > 0) {
      files.forEach(x => {
        zip.file(x.label, x.value, { base64: true });
      })
  
      label += files.map(x => x.label).join(", ") || "";

      zipAttachment = {
        label: label,
        value: await zip.generateAsync({ type: "base64" })
      }
    } 

    this.zipAttachment = zipAttachment;

    return this;
  }

  validateField = (fieldValues: Map<string, WorkOrderFieldValue>) => {
    let result = true;
    switch (this.field?.validation.type) {
      case ValidationType.Field:        
        const comparingField = fieldValues.get(this.field.validation.value);
        if (comparingField)
          result = this.compareFields(this.field?.validation.operator, comparingField);        
        break;
      default:
        break;
    }

    return result;
  }

  private compareFields = (operator: OperatorType, compareField: WorkOrderFieldValue) => {
    let comparisonResult = true;
    if (this.isNull || compareField.isNull) return false;

    switch (this.type) {
      case FieldType.Text:
      case FieldType.Number:
      case FieldType.MultiLineText:
        break;
      case FieldType.SingleSelect:
      case FieldType.TypeAhead:
        break;
      case FieldType.MultiSelect:
        break;
      case FieldType.Date:
        comparisonResult = this.compareDatesByOperator(operator, compareField.dateValue!)
        break;
      case FieldType.Attachment:
        break;
      default:
        break;
    }

    return comparisonResult
  }

  private compareDatesByOperator = (operator: OperatorType, dateValue: Date) => {
    let comparisonResult = true;

    switch (operator) {
      case OperatorType.GreaterOrEqualsThan:
        comparisonResult = moment(this.dateValue).isSameOrAfter(dateValue);
        break;
      case OperatorType.LessOrEqualsThan:
        comparisonResult = moment(this.dateValue).isSameOrBefore(dateValue);
        break;
      case OperatorType.LessThan:
        comparisonResult = moment(this.dateValue).isBefore(dateValue);
        break;
      case OperatorType.GreaterThan:
        comparisonResult = moment(this.dateValue).isAfter(dateValue);
        break;
      default:
        break;
    }

    return comparisonResult
  }

  getFieldValue = () => {
    let value;
    switch (this.type) {
      case FieldType.Text:
      case FieldType.Number:
      case FieldType.MultiLineText:
        value = this.textValue
        break;
      case FieldType.SingleSelect:
      case FieldType.TypeAhead:
        value = this.selectValue;
        break;
      case FieldType.MultiSelect:
        value = this.multipleValue;
        break;
      case FieldType.Date:
        value = this.getFlatValue();
        break;
      case FieldType.Attachment:
        value = this.zipAttachment;
        break;
      default:
        break;
    }

    return value
  }

  getRequestValue = () => {
    let value;
    switch (this.type) {
      case FieldType.TypeAhead:
        value = this.selectValue?.value;
        break;
      default:
        value = this.getFlatValue()
        break;
    }

    return value
  }

  getFlatValue = () => {
    let value;
    switch (this.type) {
      case FieldType.Text:
      case FieldType.Number:
      case FieldType.MultiLineText:
        value = this.textValue
        break;
      case FieldType.SingleSelect:
        value = this.selectValue?.value;
        break;
      case FieldType.MultiSelect:
        value = this.multipleValue?.map(x => x.value);
        break;
      case FieldType.Date:
        value = moment(this.dateValue).format("YYYY-MM-DD");
        break;
      case FieldType.TypeAhead:
        value = `${this.selectValue?.label}|XD|${this.selectValue?.value}`;
        break;
      default:
        break;
    }

    return value
  }

  getDisplayValue = () => {
    let value;
    switch (this.type) {
      case FieldType.SingleSelect:
      case FieldType.TypeAhead:
        value = this.selectValue?.label;
        break;
      case FieldType.MultiSelect:
        value = this.multipleValue?.map(x => x.label).join(",");
        break;
      case FieldType.Date:
        value = moment(this.dateValue).format(this.field?.format || "YYYY-MM-DD");
        break;
      case FieldType.Attachment:
        value = this.zipAttachment?.label;
        break;
      default:
        value = this.getFlatValue()
        break;
    }

    return value
  }

  getParentDependency = () => {
    return this.dependencies?.parent;
  }

  static toBase64 = (file) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

  static preSetFields = (fields: IPageField[] | undefined, fieldValues: Map<string, WorkOrderFieldValue>) => {
    fields?.forEach(x => {
      if (!fieldValues.has(x.name) && (x.value || !x.show)) {
        const fieldValue = new WorkOrderFieldValue(x, x.value);
        fieldValues.set(x.name, fieldValue);
      }
    })
  }
}

export interface ISectionForm {
  guid: string;
  name: string;
  deleted: boolean;
  dropDownsTriggered?: boolean;
  fieldValues: Map<string, WorkOrderFieldValue>
}