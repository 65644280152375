import { Grid } from "@material-ui/core";
import axios, { AxiosResponse, CancelToken } from "axios";
import MockAdapter from "axios-mock-adapter";
import env from 'dotenv';
import React from "react";
import { toast } from "react-toastify";
import store from "../state/store";
import { SettingsManager } from "../utils/settingsManager";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { LocalStorageHelper } from "../utils/localStorage-helper";

env.config();

const settingsManager = new SettingsManager();

axios.defaults.baseURL = settingsManager.settings.apiBaseUrl;

const responseBody = (response: AxiosResponse) => {
	return response.data
}

// NOTE for Mocking behaviour purposes
// const mock = new MockAdapter(axios, { delayResponse: 2000 });
// mock.onGet("/details").reply(401, {});

axios.interceptors.request.use(
	config => {
		const token = LocalStorageHelper.getAuthToken();

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use((response) => {
	return response;
}, (error) => {
	if (axios.isCancel(error)) {
		return new Promise(() => { });
	}

	if (error.response) {
		const { status, config } = error.response;

		const urls = [
			"/authenticate",
			"/details"
		]

		if (status === 401 && !urls.some(url => config?.url.includes(url))) {
			redirectOnUnauthorized();
		}

	} else {
		//Unable to hit API
		if (error.message.indexOf('401') > -1) {
			redirectOnUnauthorized();
		}
	}

	return Promise.reject(error.response);
});

const redirectOnUnauthorized = () => {
	toast.error((
		<React.Fragment>
			<Grid container justify="space-between" alignItems="center" style={{ padding: "0 10px", marginTop: "7px" }}>
				<Grid style={{ paddingTop: "3px" }}>
					Your session timed out, click here to log in
				</Grid>
				<Grid>
					<ErrorOutlineOutlinedIcon />
				</Grid>
			</Grid>
		</React.Fragment>
	), {
		autoClose: false,
		onClick: () => {
			window.location.href = "/login";
		}
	});
}

const requests = {
	get: (url: string, cancelToken?: CancelToken) => {
		//   console.log(axios.defaults.baseURL, url);
		if (cancelToken === undefined) {
			return axios.get(url, {
				withCredentials: true
			}).then(responseBody);
		} else {
			return axios.get(url, { cancelToken: cancelToken, withCredentials: true }).then(responseBody);
		}
	},
	genPost: (url: string, baseURL: string, body: {}, headers: {}, cancelToken?: CancelToken) => {
		if (cancelToken === undefined) {
			console.log('input: ', url, baseURL, headers);
			return axios.post(url, body, { baseURL, headers }).then(resp => {
				console.log('resp: ', resp);
				// return resp;
			});
		} else {
			return axios
				.post(url, body, { cancelToken: cancelToken })
				.then(responseBody);
		}
	},
	post: (url: string, body: any, cancelToken?: CancelToken) => {

		if (cancelToken === undefined) {
			return axios.post(url, body, {
				withCredentials: true
			}).then(responseBody);
		} else {
			return axios
				.post(url, body, { cancelToken: cancelToken, withCredentials: true })
				.then(responseBody);
		}
	},
	put: (url: string, body: {}, cancelToken?: CancelToken) => {
		if (cancelToken === undefined) {
			return axios.put(url, body, {
				withCredentials: true
			}).then(responseBody);
		} else {
			return axios
				.put(url, body, { cancelToken: cancelToken, withCredentials: true })
				.then(responseBody);
		}
	},
	del: (url: string, cancelToken?: CancelToken) => {
		if (cancelToken === undefined) {
			return axios.delete(url, {
				withCredentials: true
			}).then(responseBody);
		} else {
			return axios.delete(url, { cancelToken: cancelToken, withCredentials: true }).then(responseBody);
		}
	},
};

export default requests;