import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import LoadingIndicator from "../components/loadingIndicator";
import { resetAction, updateTokenAction } from "../state/actions/appAction";
import { SettingsManager } from "../utils/settingsManager";
import { LocalStorageHelper } from "../utils/localStorage-helper";

function useQuery() {
  return new URLSearchParams(useLocation().hash.substring(1));
}

const LoginPage = () => {
  const query = useQuery();
  const token = query.get("access_token");
  const dispatch = useDispatch();
  const history = useHistory();
  const settingsManager = new SettingsManager();

  LocalStorageHelper.clearAuthToken();  
  dispatch(resetAction());

  if (!token) {
    window.location.href = settingsManager.getAuthProviderUrl();
  } else {
    window.history.replaceState({}, document.title, `/${SettingsManager.CALLBACK_PATH}`);
    dispatch(updateTokenAction(token));
    history.push("/");
  }

  return (
    <LoadingIndicator />
  )
}

export default LoginPage;
