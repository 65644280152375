import env from 'dotenv';
env.config();

export interface IAppSettings {
	apiBaseUrl: string;
	authBaseUrl: string;
	clientId: string;
	apps: portal[];
}

export interface portal {
	id: number,
	baseUrl: string
}

export class SettingsManager {
	settings: IAppSettings;

	public static CALLBACK_PATH = "auth/myidcallback";

	constructor() {
		this.settings = window['runConfig'] as IAppSettings;
	}

	getRedirectUrl = () => `${window.location.protocol}//${window.location.host}/${SettingsManager.CALLBACK_PATH}`;

	getAuthProviderUrl = () => {
		const clientIdParam = `client_id=${this.settings.clientId}`;
		const responseTypeParam = "response_type=token";
		const redirectUriParam = `redirect_uri=${this.getRedirectUrl()}`;
        const nonceParam = `nonce=${new Date().getTime()}`;
		const scopeParam = "scope=openid";

		return `${this.settings.authBaseUrl}?${clientIdParam}&${responseTypeParam}&${redirectUriParam}&${nonceParam}&${scopeParam}`;
	}
}