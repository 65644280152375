import { IActionStatus, IActionStatusFormFields, IFieldOption, IPageField } from '../../../models/appDetails';
import useWorkOrderStyles from "./workOrderStyles";
import InputField from "./fields/inputField";
import MultiLineInputField from "./fields/multiLineinputField";
import SelectField from "./fields/selectField";
import MultiSelectField from "./fields/multiSelectField";
import DateField from "./fields/dateField";
import TypeAheadField from "./fields/typeAheadField";
import AttachmentField from "./fields/attachment/attachmentField";
import React, {useState, Fragment } from 'react';
import { Grid, GridSize } from '@material-ui/core';
import { WorkOrderFieldValue } from '../../../models/workOrderFieldValue';
import { FieldType } from '../../../models/enums';
import dropdownService from '../../../api/services/dropdownService';
import { FieldAssociationRequest, IFieldAssociationParent } from '../../../models/payloads/fieldAssociationPayload';
import { Notifications } from '../notifications/Notifications';
import { IFieldsValidationResult } from '../../../models/workOrder';
import sortByRules from "../../../utils/dropdown-helper";
import MessageDialog from '../notifications/MessageDialog';
import { useSelector } from 'react-redux';
import { IAppStore } from '../../../state/store';

interface Props {
  formKey?: string;
  workOrderType?: string;
  gridSize?: GridSize | boolean;
	fields: IPageField[];
  initialfieldValues: Map<string, WorkOrderFieldValue>;
  actionFormFields?: IActionStatusFormFields;
  setParentFieldValues: (fieldValue: WorkOrderFieldValue, formKey?: string) => void;
  setValidationDispatcher?: (formKey: string, requiredDispatcher: () => IFieldsValidationResult, additionalValidationDispatcher: () => IFieldsValidationResult) => void;
  triggerDropdownChange?: (handleDropdownChange: (fieldValue: WorkOrderFieldValue, justUpdateMap: boolean) => void, formId?: string) => void;
  setSubmitButtonDisabled?: (isDisplay: boolean) => void;
}

interface childrenDropdown {
  name: string,
  field: IPageField | undefined,
  resetValueDispatcher?: React.Dispatch<any>,
  setOptionsDispatcher?: React.Dispatch<React.SetStateAction<IFieldOption[]>>
}

const WorkOrderForm: React.FC<Props> = ({
  formKey,
  workOrderType,
  gridSize,
  fields,
  initialfieldValues,
  actionFormFields,
  setParentFieldValues,
  setValidationDispatcher,
  triggerDropdownChange,
  setSubmitButtonDisabled,
}) => {
  const classes = useWorkOrderStyles();
  const gridItemCol = gridSize || true;
	const notifications = new Notifications(React.useRef(null));
  var woDetails = useSelector((state: IAppStore) => state.appDetails);

  let formValues = initialfieldValues || new Map<string, WorkOrderFieldValue>();
  let dropdownOptionsDispatcher = new Map<string, React.Dispatch<React.SetStateAction<IFieldOption[]>>>();
  let dropdownValueResetDispatcher = new Map<string, React.Dispatch<React.SetStateAction<any>>>();
  let fieldIsMissingDispatcher = new Map<string, React.Dispatch<React.SetStateAction<boolean>>>();
  let fieldIsLoadingDispatcher = new Map<string, React.Dispatch<React.SetStateAction<boolean>>>();
  const [displayDTCAlert, setDisplayDTCAlert] = useState(false);
  const [shouldClearAirline, setShouldClearAirline] = useState(false);
  const [shouldClearTitle, setShouldClearTitle] = useState(false);

  const dateFields = ['startDate', 'endDate'];
  const workOrderStatusList = ['Completed', 'All Deliverables Sent to Lab'];

  const isReadOnly = (field: IPageField) => {
    if (!actionFormFields || actionFormFields?.isNew) {
      return field.readOnly;
    }

    let isIncluded = false;
    if (actionFormFields.readOnlyExceptions?.includes(field.name)) {
      isIncluded = true
    }

    const workOrderStatus = formValues.get('workOrderStatus')?.dynamicValue;
    //date fields rule for Completed and  All Deliverables Sent to Lab
    if (dateFields.includes(field.name) && workOrderStatus && workOrderStatusList.includes(workOrderStatus.toString())) {
      const dateField = formValues.get(field.name);
      
      const currentDate = new Date();
      const currentDateWithoutHour = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
      
      const initialDateValue = dateField?.dynamicValue;
      if(initialDateValue){
        const formDate = new Date(initialDateValue.toString());
        const formDateWithoutHour = new Date(formDate.getFullYear(), formDate.getMonth(), formDate.getDate());

        if (formDateWithoutHour > currentDateWithoutHour) {
          return false;
        }
      }    
      return true;
    }

    if (actionFormFields?.readOnly) {
      if (isIncluded) {
        return false
      }
      return true;
    } else {
      if (isIncluded) {
        return true
      }
      return false;
    }
  }

  const isVisible = (field: IPageField) => {
    if (!actionFormFields || actionFormFields?.isNew) {
      return field.show;
    }

    let isIncluded = false;
    if (actionFormFields.showOnlyExceptions?.includes(field.name)) {
      isIncluded = true
    }

    if (actionFormFields?.showOnly) {
      if (isIncluded) {
        return false
      }
      return true;
    } else {
      if (isIncluded) {
        return true
      }
      return false;
    }
  }

  const renderForm = () => {
    return [...fields || []].map(opts => {
      let field: any = null;
      const fieldValues = formValues.get(opts.name) as WorkOrderFieldValue;
      let fieldDef = {...opts};
      fieldDef.readOnly = (isReadOnly(opts) || false);
      fieldDef.show = (isVisible(opts) || false);
      switch (fieldDef.type) {
        case FieldType.SingleSelect:
          field = renderSingleSelect(fieldDef, fieldValues);  
        break;
        case FieldType.MultiSelect:
          field = renderMultiSelect(fieldDef, fieldValues);  
        break;
        case FieldType.Date:
          field = renderDateSelect(fieldDef, fieldValues);  
        break;
        case FieldType.MultiLineText:
          field = renderMultiLineInput(fieldDef, fieldValues);  
        break;
        case FieldType.TypeAhead:
          field = renderTypeAhead(fieldDef, fieldValues);  
        break;
        case FieldType.Attachment:
          field = renderAttachment(fieldDef, fieldValues);  
        break;
        case FieldType.Text:
        case FieldType.Number:
        default:
          field = renderTextInput(fieldDef, fieldValues);
          break;
      }

      return field;
    })
  }

  const handleInputChange = (fieldValue: WorkOrderFieldValue, updateParentField = true) => {
    formValues.set(fieldValue.name, fieldValue);
    if (updateParentField) {
      setParentFieldValues(fieldValue, formKey);
    }

    const airlineFieldName = "airlines";
    const titleFieldName = "catORtitle";

    const airlineObject = formValues.get(airlineFieldName); //airline field
    const titleObject = formValues.get(titleFieldName); //title field

    const displayPopup = (airlineObject: WorkOrderFieldValue , titleObject: WorkOrderFieldValue) => {    
      // Check if airline and title fields are selected
      if (!airlineObject || !titleObject) {
        return true;
      }

      const airlineId = airlineObject.selectValue?.value;
      const titleLicensedGroups = titleObject.selectValue?.licensedGroups || [];
      // Check if the airline has title license
      if (airlineId && titleLicensedGroups.includes(airlineId)) {
          return false
      }
  
      const titleContentEntity = titleObject.selectValue?.contentEntity;
      const airlineContentEntities = airlineObject.selectValue?.contentEntities || [];
      // Check if the airline has the same title content entity
      if (titleContentEntity && airlineContentEntities.includes(titleContentEntity)) {
          return false;
      }

      return true;
    };

    const userActionFlags = woDetails.userActionFlags;
    const isTitleGatekeepingRuleInactive = userActionFlags["workorder-title-gk"] || false;
    const isAdmin = userActionFlags["workorder-admin-action-id"] || false;
    // Do not execute title GK rule if TitleGatekeepingRule is inactive or is admin user
    if (!isAdmin && !isTitleGatekeepingRuleInactive){
      
      if (airlineObject && titleObject && titleObject.selectValue) {
        const shouldShowAlert = displayPopup(airlineObject, titleObject);
        if (shouldShowAlert) {
            setDisplayDTCAlert(true);
            setSubmitButtonDisabled?.(true);
        
            if (fieldValue.name === airlineFieldName) {
                setShouldClearAirline(true);
                setShouldClearTitle(false);
            } else if (fieldValue.name === titleFieldName) {
                setShouldClearTitle(true);
                setShouldClearAirline(false);
            }     
        }else{
          setSubmitButtonDisabled?.(false);
          setShouldClearAirline(false);
          setShouldClearTitle(false);
        }
      }
  
      if((titleObject && !titleObject.selectValue) || !titleObject){
        setShouldClearTitle(false);
      }
    }
  }

  const validateRequiredFields = () => {
    let result: IFieldsValidationResult = {
      name: workOrderType as string,
      fields: []
    };

    const fieldsToValidate = fields.filter(x => x.show && x.required) || [];

    for (const field of fieldsToValidate) {
			const fieldValue = formValues.get(field.name);
      const dispatch = fieldIsMissingDispatcher.get(field.name);

			if (!fieldValue || fieldValue.isNull) {
				result.fields.push({name: field.label});
        if (dispatch) dispatch(true);
			} 

		}
    return result;
  }

  const validateFields = () => {
    let result: IFieldsValidationResult = {
      name: workOrderType as string,
      fields: []
    };

    const fieldsToValidate = fields.filter(x => x.show && x.validation.operator) || [];

    for (const field of fieldsToValidate) {
			const fieldValue = formValues.get(field.name);
      const dispatch = fieldIsMissingDispatcher.get(field.name);

			if (fieldValue && !fieldValue.validateField(formValues)) {
				result.fields.push({name: field.label, message: field.validation.message});
        if (dispatch) dispatch(true);
        break;
			} 

		}
    return result;
  }

  if (setValidationDispatcher) setValidationDispatcher(formKey as string, validateRequiredFields, validateFields);

  const setOptionsDispatcher = (name: string,
    dispatcher: React.Dispatch<React.SetStateAction<IFieldOption[]>>) => {
    dropdownOptionsDispatcher.set(name, dispatcher);
  }

  const setValueResetDispatcher = (name: string,
    dispatcher: React.Dispatch<React.SetStateAction<any>>) => {
    dropdownValueResetDispatcher.set(name, dispatcher)
  }

  const setIsMissingDispatcher = (name: string,
    dispatcher: React.Dispatch<React.SetStateAction<boolean>>) => {
      fieldIsMissingDispatcher.set(name, dispatcher);
  }

  const setFieldEmpty = (fieldName: string, isEmpty: boolean) => {
    if(isEmpty)
    {
      formValues.delete(fieldName);
    }
  }

  const setIsLoadingDispatcher = (name: string,
    dispatcher: React.Dispatch<React.SetStateAction<boolean>>) => {
      fieldIsLoadingDispatcher.set(name, dispatcher);
  }

  const resetChildDropdowns = (dropdowns: childrenDropdown[]) => {
    dropdowns.forEach(x => {
      if (x.resetValueDispatcher) {
        x.resetValueDispatcher(null);
        handleInputChange(new WorkOrderFieldValue(x.field, null))
      }
      if (x.setOptionsDispatcher) x.setOptionsDispatcher([]);
    })
  }
  
  const handleDropdownChange = (fieldValue: WorkOrderFieldValue, justUpdateMap: boolean = false, updateParentField = true) => {
    handleInputChange(fieldValue, updateParentField)
    if (justUpdateMap) return;

    if (fieldValue.hasDependencies) {      
      const childFieldDependencies = getChildDependencies(fieldValue.field!);
      const firstChildLoading = fieldIsLoadingDispatcher.get(fieldValue.field?.dependencies?.child as string);
      if (fieldValue.isNull) {
        resetChildDropdowns(childFieldDependencies);
      } else {
        const payload = new FieldAssociationRequest(
          workOrderType as string, 
          fieldValue.name, 
          fieldValue.getRequestValue(), 
          getParentDependency(fieldValue));

        resetChildDropdowns(childFieldDependencies);

        if (firstChildLoading) firstChildLoading(true);

        dropdownService.getOptions(payload).then(res => {
          if (firstChildLoading) firstChildLoading(false);
          res.results.forEach(x => {
            const fieldDef = fields.find(f => f.name == x.field);
            if (fieldDef?.sortOptions) {
              x.values = sortByRules(x.values, fieldDef.sortOptions);
            }

            const childDependency = childFieldDependencies.find(c => c.name === x.field);
            
            if (childDependency) {
              if (childDependency.setOptionsDispatcher) childDependency.setOptionsDispatcher(x.values);
            }
            
          })

        }).catch(err => {
          if (firstChildLoading) firstChildLoading(false);
          resetChildDropdowns(childFieldDependencies);
        })
      }
    }
  }


  const getChildDependencies = (field: IPageField) => {
    const childField = fields.find(x => x.name == field.dependencies?.child);
    let result: childrenDropdown[] = [];

    if (childField) {
      result.push({
        name: childField.name,
        field: childField,
        resetValueDispatcher: dropdownValueResetDispatcher.get(childField.name),
        setOptionsDispatcher: dropdownOptionsDispatcher.get(childField.name)
      })
      result = [...result, ...getChildDependencies(childField)]
    }

    return result;
  }

  const getParentDependency = (fieldValue: WorkOrderFieldValue) => {
    const parentName = fieldValue.getParentDependency();

    if (parentName) {
      const parent = initialfieldValues.get(parentName) as WorkOrderFieldValue;
      let parentAssociation: IFieldAssociationParent = {
        field: parentName as string,
        value: parent.getRequestValue(),
        workOrderType: workOrderType as string,
        parent: getParentDependency(parent)
      }

      return parentAssociation;
    }
  }

  if (triggerDropdownChange) {
    triggerDropdownChange(handleDropdownChange, formKey);
  }

  const renderTextInput = (field: IPageField, fieldValues: WorkOrderFieldValue) => {
    return (
      <InputField key={`${workOrderType}-field-${field.name}`} 
        field={field}
        initialValue={fieldValues?.textValue}        
        gridSize={gridItemCol}
        setFormValue={fieldValue => handleInputChange(fieldValue) } 
        setIsMissingDispatcher={setIsMissingDispatcher}/>
    )
  }

  const renderMultiLineInput = (field: IPageField, fieldValues: WorkOrderFieldValue) => {
    return (
      <MultiLineInputField key={`${workOrderType}-field-${field.name}`} 
        field={field}
        initialValue={fieldValues?.textValue}         
        gridSize={gridItemCol} 
        setFormValue={fieldValue => handleInputChange(fieldValue) }  
        setIsMissingDispatcher={setIsMissingDispatcher}/>
    )
  }

  const renderSingleSelect = (field: IPageField, fieldValues: WorkOrderFieldValue) => {
    return (
      <SelectField key={`${workOrderType}-field-${field.name}`} 
        field={field}
        initialValue={fieldValues?.selectValue as IFieldOption}        
        initialOptions={fieldValues?.initialOptions || [] as IFieldOption[]}
        gridSize={gridItemCol} 
        setFormValue={fieldValue => handleDropdownChange(fieldValue) }
        setFormOptionDispatcher={setOptionsDispatcher}  
        setIsMissingDispatcher={setIsMissingDispatcher}
        setValueResetDispatcher={setValueResetDispatcher}
        setLoadingDispatcher={setIsLoadingDispatcher}
        shouldClearValue={shouldClearAirline}/>
    )
  }

  const renderMultiSelect = (field: IPageField, fieldValues: WorkOrderFieldValue) => {
    return (
      <MultiSelectField key={`${workOrderType}-field-${field.name}`} 
        field={field}
        initialValue={fieldValues?.multipleValue  as IFieldOption[]}        
        initialOptions={fieldValues?.initialOptions || [] as IFieldOption[]}
        gridSize={gridItemCol} 
        setFormValue={fieldValue => handleDropdownChange(fieldValue) }
        setFormOptionDispatcher={setOptionsDispatcher}  
        setIsMissingDispatcher={setIsMissingDispatcher}
        setValueResetDispatcher={setValueResetDispatcher}
        setLoadingDispatcher={setIsLoadingDispatcher}/>
    )
  }

  const renderDateSelect = (field: IPageField, fieldValues: WorkOrderFieldValue) => {
    return (
      <DateField key={`${workOrderType}-field-${field.name}`} 
        field={field}
        initialValue={fieldValues?.dateValue}         
        gridSize={gridItemCol} 
        setFormValue={fieldValue => handleInputChange(fieldValue) }  
        setIsMissingDispatcher={setIsMissingDispatcher} />
    )
  }

  const renderTypeAhead = (field: IPageField, fieldValues: WorkOrderFieldValue) => {
    return (
      <TypeAheadField key={`${workOrderType}-field-${field.name}`} 
        field={field}
        initialValue={fieldValues?.selectValue as IFieldOption}         
        initialOptions={fieldValues?.initialOptions || [] as IFieldOption[]}
        gridSize={gridItemCol} 
        setFormValue={fieldValue => handleDropdownChange(fieldValue) } 
        setIsMissingDispatcher={setIsMissingDispatcher}
        shouldClearValue={shouldClearTitle}
        setFieldEmpty = {setFieldEmpty} />
    )
  }

  const renderAttachment = (field: IPageField, fieldValues: WorkOrderFieldValue) => {
    return (
      <AttachmentField key={`${workOrderType}-field-${field.name}`} 
        field={field}
        initialValue={{
          zipFile: fieldValues?.zipAttachment,
          files: fieldValues?.attachments
        }}        
        gridSize={gridItemCol} 
        setFormValue={fieldValue => handleInputChange(fieldValue) } 
        setIsMissingDispatcher={setIsMissingDispatcher}/>
    )
  }

  const handleDTCDialogOnClose = async (isConfirm = false) => {
		setDisplayDTCAlert(isConfirm);
	}

  return (
    <Fragment>
      <Grid key="a" container spacing={5} direction="row" >
        {renderForm()}
      </Grid>
      {displayDTCAlert && (
        <MessageDialog 
          open={displayDTCAlert}
          hideCloseBtn={false}
          onClose={() => handleDTCDialogOnClose()}
          title="Please update your request before proceeding"
          body="Sorry, one or more of the title(s) involved in your request is not permitted to the airline chosen. Please update your request before proceeding."
        />
      )}
    </Fragment>
  );
}

export default WorkOrderForm;