import React, { Fragment, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Autocomplete } from '@material-ui/lab';
import useWorkOrderStyles from "../workOrderStyles";
import { IFieldOption, IPageField } from '../../../../models/appDetails';
import { WorkOrderFieldValue } from '../../../../models/workOrderFieldValue';
import { FieldType } from '../../../../models/enums';
import { CircularProgress, Grid, GridSize, TextField } from '@material-ui/core';

interface Props {
	field: IPageField;
  initialValue?: IFieldOption | null;
  initialOptions: IFieldOption[]
  gridSize: GridSize | boolean;
  isReadOnly?: boolean;
  setFormValue: (fieldValue: WorkOrderFieldValue, justUpdateMap: boolean) => void;
  setFormOptionDispatcher: (name: string, 
    dispatcher: React.Dispatch<React.SetStateAction<IFieldOption[]>>) => void;
  setValueResetDispatcher?: (name: string, 
    dispatcher: React.Dispatch<React.SetStateAction<any>>) => void;
  setIsMissingDispatcher: (name: string, 
    dispatcher: React.Dispatch<React.SetStateAction<boolean>>) => void;
  setLoadingDispatcher?: (name: string, 
    dispatcher: React.Dispatch<React.SetStateAction<boolean>>) => void;
  shouldClearValue: boolean;
}

const SelectField: React.FC<Props> = ({
  field,
  initialValue,
  initialOptions,
  gridSize,
  isReadOnly,
  setFormValue,
  setFormOptionDispatcher,
  setIsMissingDispatcher,
  setValueResetDispatcher,
  setLoadingDispatcher,
  shouldClearValue
}) => {
  const airlineFieldName = "airlines";
  const [value, setValue] = React.useState<IFieldOption | null | undefined>(initialValue);
  const [isValueMissing, setIsMissing] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<IFieldOption[]>(
    initialOptions.length > 0 ? initialOptions : (field.options || [])
  );
  const [loading, setLoading] = React.useState(false);
  const classes = useWorkOrderStyles();

  const sendValueToForm = (value: IFieldOption | null, justUpdateMap: boolean = false) => {
    const fieldValue = new WorkOrderFieldValue(field, value).addInitialOptions(options);
    setFormValue(fieldValue, justUpdateMap);

    if (fieldValue.isRequired && fieldValue.isNull) {
      setIsMissing(true)
    } else {
      setIsMissing(false)
    }
  }

  if (options.length == 0 && value != null) {
    setOptions([value]);
  }

  const key = `${field.guid}-${field.name}`;
  
  const handleOnChange = (item: IFieldOption | null) => {
    sendValueToForm(item);
    setValue(item as IFieldOption);
  }

  setFormOptionDispatcher(field.name, setOptions);
  setIsMissingDispatcher(field.name, setIsMissing);

  if (setValueResetDispatcher) {
    setValueResetDispatcher(field.name, setValue);
  }

  if (setLoadingDispatcher) {
    setLoadingDispatcher(field.name, setLoading);
  }

  useEffect(() => {
    if (shouldClearValue && (field.name === airlineFieldName)) {
      handleOnChange(null);
    }
  }, [shouldClearValue]);

  return (
    <Fragment key={`${key}-fragment`}>
      <Grid key={`${key}-item`} item xs={gridSize} className={!field.show ? classes.hidden : ""}>
        <Autocomplete
          id={key}
          key={key}
          options={options}          
          fullWidth
          disabled={field.readOnly || false}
          getOptionSelected={(option, selected) => option.value === selected.value}
          getOptionLabel={(o: IFieldOption) => o.label}
          forcePopupIcon={!loading}
          disableClearable={loading ? true : undefined}
          renderInput={(params) => 
            <TextField {...params}
              error={isValueMissing}
              // helperText={isValueMissing ? "" : null} 
              label={field.label} 
              required={field.required}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {params.InputProps.endAdornment}
                    {loading ? <CircularProgress color="inherit" size={20} /> : null }
                  </React.Fragment>
                ),
              }}/>
          }
          value={value || { label: '', value: '' }}
          onChange={(e, value) => handleOnChange(value as IFieldOption)}
        />
      </Grid>
    </Fragment>
  )

}

export default SelectField;