import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { IActionStatus, IPageField, ISection } from '../../models/appDetails';
import { IWorkOrderSection } from '../../models/workOrderSection';
import { Grid } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { WorkOrderFieldValue } from '../../models/workOrderFieldValue';

const useTableStyles = makeStyles({
  root: {
    tableLayout: "fixed",
    '& > thead > tr > th': {
      borderRight: "1px solid lightGray"
    }
    
  },
  paperContainer: {
    overflowX: 'unset'
  },
  headerCell: {
    fontSize: "0.775rem"
  },
});

const useRowStyles = makeStyles({
  collapsableCell: {
    paddingBottom: "0px",
    paddingTop: "0px",
    borderBottom: "lightgray 1px solid",
    paddingRight: "0"
  },
  subTable: {
    backgroundColor: "#d3d3d3"
  },
  collapsableBtnCell: {
    paddingLeft: "7px"
  },
  gridContainer: {
    maxWidth: "1200px"
  }
});

const Header = (props: { field: IPageField }) => {
  const { field } = props;
  const classes = useTableStyles()
  return (
    <TableCell width={field.size} className={classes.headerCell}>{field.label}</TableCell>
  )
}

const Cell = (props: { fieldValue?: WorkOrderFieldValue }) => {
  const { fieldValue } = props;
  return (
    <TableCell>{fieldValue ? fieldValue.getFlatValue() : ""}</TableCell>
  )
}

const Row = (props: {
  actionStatus?: IActionStatus,
  sectionDef: ISection,
  section: IWorkOrderSection,
  onEditClick: (id: string) => void,
  onDeleteClick: (id: string) => void,
  onCloneClick: (id: string) => void,
}) => {
  const { actionStatus, sectionDef, section, onEditClick, onDeleteClick, onCloneClick } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const colspan = (sectionDef.createFields.filter(x => x.showInTable).length ?? 1);

  const renderBtns = () => {
    if (actionStatus && !actionStatus.formFields.addSection) {
      return null;
    }
    
    return(
      <Fragment>
        <Grid item>
          <IconButton component="span" size="small" onClick={() => onCloneClick(section.guid)}>
            <FileCopyIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton component="span" size="small" onClick={() => onDeleteClick(section.guid)}>
            <HighlightOffIcon />
          </IconButton>
        </Grid>
      </Fragment>
    )
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell className={classes.collapsableBtnCell}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Grid container justify="center">
            <Grid item>
              <IconButton component="span" size="small" onClick={() => onEditClick(section.guid)}>
                <EditIcon />
              </IconButton>
            </Grid>
            {renderBtns()}
          </Grid>
        </TableCell>
        {sectionDef.createFields.filter(x => x.showInTable).map((field, index) => (
          <TableCell key={`cell-${field.name}-${index}`}>
            {section.section.get(field.name)?.getFlatValue()}
          </TableCell>
        ))}
      </TableRow>
      <TableRow style={{ display: !open ? "none" : "" }}>
        <TableCell className={classes.collapsableCell} colSpan={colspan}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container spacing={1} alignItems="center" alignContent="center" className={classes.gridContainer}>
              <Grid item xs={2}>
                <strong>{sectionDef.section?.display}</strong>
              </Grid>
              <Grid item xs={10}>
                <Box>
                  <Table className={classes.subTable} size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        {sectionDef.section?.createFields.filter(x => x.showInTable).map((field, index) => (
                          <Header key={`header-${field.name}-${index}`} field={field} />
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {section.subSections.map((subSection, index) => {
                        return (
                          <TableRow key={`subrow-${index}`}>
                            {sectionDef.section?.createFields.filter(x => x.showInTable).map((field, index2) => {
                              return (
                                <TableCell key={`subcell-${index2}`}>
                                  {subSection.fieldValues.get(field.name)?.getFlatValue()}
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </Box>
                
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

interface IProps {
  actionStatus?: IActionStatus,
  sectionDef: ISection,
  sections: IWorkOrderSection[],
  onEditClick: (id: string) => void,
  onDeleteClick: (id: string) => void,
  onCloneClick: (id: string) => void
}

const WorkOrderSectionGrid: React.FC<IProps> = ({
  actionStatus,
  sectionDef,
  sections,
  onEditClick,
  onDeleteClick,
  onCloneClick
}) => {
  const classes = useTableStyles();
  const collapseWidth = 40;
  const actionsWidth = 130

  const tableWidth = sectionDef.createFields.filter(x => x.showInTable).map(x => x.size).reduce((total, size) => total + (size || 0)) + collapseWidth + actionsWidth;

  return (
    <Table className={classes.root} width={tableWidth}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.headerCell} width={collapseWidth}/>
          <TableCell className={classes.headerCell} width={actionsWidth}>Actions</TableCell>
          {sectionDef.createFields.filter(x => x.showInTable).map(field => (
            <Header key={`header-${field.name}`} field={field} />
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sections.filter(x => !x.deleted).map((section) => (
          <Row key={`row-${section.guid}`}
            actionStatus={actionStatus}
            sectionDef={sectionDef}
            section={section} 
            onDeleteClick={onDeleteClick}
            onEditClick={onEditClick}
            onCloneClick={onCloneClick}
            />
        ))}
      </TableBody>
    </Table>
    // <TableContainer component={Paper} className={classes.paperContainer}>
    // </TableContainer>
  );
}

export default WorkOrderSectionGrid;