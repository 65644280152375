import { GridSortItem } from '@material-ui/data-grid';
import { Reducer } from 'redux';
import { IKeyValuePair } from '../../models/payloads/workorderPayloadResponse';
import { WorkOrderFieldValue } from '../../models/workOrderFieldValue';
import { SearchActionTypes, SearchActions } from '../actions/searchAction';

export interface ISearchState {
    rows: IKeyValuePair[];
    selectedRows: IKeyValuePair[];
    filters: WorkOrderFieldValue[];
    isSearching: boolean;
    firstSearch?: boolean;
    sortModel: GridSortItem[];
    blockUI: boolean;
}

const initialSearchState: ISearchState = {
    rows: [],
    selectedRows: [],
    filters: [],
    isSearching: false,
    firstSearch: true,
    sortModel: [],
    blockUI: false
};

export const searchReducer: Reducer<ISearchState, SearchActions> = (state = initialSearchState, action) => {
    switch (action.type) {
      case SearchActionTypes.SEARCH_GET:
        return {
          ...state,
          rows: action.rows,
          firstSearch: false,
          isSearching: false
        };    
      case SearchActionTypes.SHOW_INDICATOR:
        return {
          ...state,
          filters: action.filters,
          isSearching: true
        };
      case SearchActionTypes.SELECT_ROWS:
        return {
          ...state,
          selectedRows: action.selectedRows,
        };
      case SearchActionTypes.RESET_SEARCH:
        return {
          ...state,
          firstSearch: true,
          blockUI: false,
          selectedRows: []
        };
      case SearchActionTypes.SORT_SEARCH:
        return {
          ...state,
          sortModel: [...action.sortItem]
        };
      case SearchActionTypes.BLOCK_UI:
        return {
          ...state,
          blockUI: action.blockUI
        };
      default:
        return state;
    }
  };
