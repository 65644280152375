import { ActionType, OriginationSource } from './../../models/enums';
import { ThunkAction } from 'redux-thunk';
import { ActionCreator, Dispatch } from 'redux';
import { IWorkOrderSection } from '../../models/workOrderSection';
import { WorkOrderFieldValue } from './../../models/workOrderFieldValue';
import { IWorkOrderState } from '../reducers/workOrderReducer';

export enum WorkOrderActionTypes {
  UPDATE_WORKORDER = 'UPDATE_WORKORDER',
  RESET_WORKORDER = 'RESET_WORKORDER'
}

export interface IClonedWorkOrder {
  workorder: Map<string, WorkOrderFieldValue>;
  sections: IWorkOrderSection[];
  isAdminClone: boolean;
  workOrderSourceId: any;
}

export interface IWorkOrderAction {
  type: WorkOrderActionTypes,
  input: {
    id: string;
    status: string,
    action: ActionType,
    origin: OriginationSource,
    cloned: IClonedWorkOrder,
    allowResubmit: boolean
  }
}

export type WorkOrderActions = IWorkOrderAction;

export const updateAction: ActionCreator<ThunkAction<Promise<any>, IWorkOrderState, null, IWorkOrderAction>> = (id: string, status: string, action: ActionType, origin: OriginationSource, reSubmit: boolean, cloned?: IClonedWorkOrder) => {
  return async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: WorkOrderActionTypes.UPDATE_WORKORDER,
            input: {
              id,
              status,
              action,
              origin,
              cloned,
              allowResubmit: reSubmit
            }
        })
    } catch (err) {
        console.error('Error: ', err);
    }
  };
}

export const resetAction: ActionCreator<ThunkAction<Promise<any>, IWorkOrderState, null, IWorkOrderAction>> = () => {
  return async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: WorkOrderActionTypes.RESET_WORKORDER,            
        })
    } catch (err) {
        console.error('Error: ', err);
    }
  };
}