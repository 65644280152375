import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import { toast } from 'react-toastify';
import { Grid } from '@material-ui/core';

class toastProps {
  time?: number;
  width?: number;
}

export class Notifications {

  private toastId: any;
  private inProgress = false;
  private keepInProgress = false;

  constructor(ref: React.MutableRefObject<null>) {
    this.toastId = ref;
  }

  success = (message: string | JSX.Element, props: toastProps = {}) => {
    if (this.inProgress) {
      toast.update(this.toastId.current, {
        type: toast.TYPE.SUCCESS, 
        render: this.renderToast(message, <CheckCircleOutlinedIcon />),
        autoClose: props.time,
        style: props.width ? { width: props.width } : null,
        closeOnClick: true,
        onClose: () => { this.inProgress = false; } 
      });
    } else {
      toast.success(this.renderToast(message, <CheckCircleOutlinedIcon />), {
        style: props.width ? { width: props.width } : undefined,
        closeOnClick: true,
      });
    }

    this.inProgress = false;
    this.keepInProgress = false;
  }

  error = (message: string | JSX.Element, autoClose = false) => {
    if (this.inProgress) {
      toast.update(this.toastId.current, { 
        type: toast.TYPE.ERROR, 
        render: this.renderToast(message, <ErrorOutlineOutlinedIcon />),
        autoClose: autoClose ? 5000 : false,
        closeOnClick: true,
        onClose: () => { this.inProgress = false; }
      });
    } else {
      toast.error(this.renderToast(message, <ErrorOutlineOutlinedIcon />), {
        autoClose: autoClose ? 5000 : false,
        closeOnClick: true,
      });
    }

    this.inProgress = false;
    this.keepInProgress = false;
  }

  info = (message: string | JSX.Element) => {
    if (this.inProgress) {
      toast.update(this.toastId.current, { 
        type: toast.TYPE.INFO, 
        render: this.renderToast(message, <InfoOutlinedIcon />),
        autoClose: null,
        closeOnClick: true,
        onClose: () => { this.inProgress = false; }
      });
    } else {
      toast.info(this.renderToast(message, <InfoOutlinedIcon />));
    }
    this.inProgress = false;
    this.keepInProgress = false;
  }
  
  warning = (message: string | JSX.Element) => {
    toast.warning(this.renderToast(message, <WarningOutlinedIcon />));
    this.inProgress = false;
    this.keepInProgress = false;
  }

  progress = (message: string | JSX.Element, keepInProgress?: boolean) => {
    this.inProgress = true;    

    if (this.keepInProgress) {
      toast.update(this.toastId.current, { 
        type: toast.TYPE.INFO, 
        render: this.renderToast(message, <CircularProgress color="inherit" size={20} />),
        autoClose: false,
        closeOnClick: false
      });
    } else {
      this.toastId.current = toast.info(
        this.renderToast(message, <CircularProgress color="inherit" size={20} />),
        {
          autoClose: false,
          closeOnClick: false
        }
      )
    }

    this.keepInProgress = keepInProgress ?? this.keepInProgress;
  }

  kill = () => {
    toast.dismiss(this.toastId.current);
    this.inProgress = false;
    this.keepInProgress = false;
  }

  private renderToast(message: string | JSX.Element, icon: JSX.Element) {
    return (
      <React.Fragment>
        <Grid container justify="space-between" alignItems="center" style={{ padding: "0 10px", marginTop: "7px" }}>
          <Grid style={{ paddingTop: "3px" }}>
            {message}
          </Grid>
          <Grid>
            {icon}
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}