import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useBulkSharedStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialogPaper: {
      height: "100%"
    },
		dialogTitle: {
      marginBottom: -20
    },
		dialogGridContainer: {
      padding: "10px 24px 25px", 
      marginBottom: 0
    },
		dialogContent: {
      borderTop: "1px solid lightgray"
    },
    clearBtn: {
      padding: 0
    },
    selectionsTitle: {
      fontSize: "16px"
    },
    titleList: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      border: "solid 1px lightgray",
      borderRadius: "3px",
      overflow: "auto",
      maxHeight:" 75vh"
    },
    titleItem: {
      paddingTop: 0,
      paddingBottom: 0,
      cursor: "pointer",      
      "&:hover": {
        background: "lightgray"
      }
    },
    titleItemIcon: {
      minWidth: "30px",
      marginTop: "-2px"
    }
	}),
);

export default useBulkSharedStyles;