import React, { useState } from 'react';
import agent from '../../api/services/genService';
import { LocalStorageHelper } from "../../utils/localStorage-helper";
import { useStyles } from './Reauth.page.styles';

const Reauth: React.FC = () => {
    const [token, setToken] = useState('');
    const classes = useStyles();

    const handleOnClick = async () => {
        const response = await agent.UserInfo.getInfo(token);
        setToken('');
        if (response && response) {
            LocalStorageHelper.setAuthToken(response);
            window.location.href = "/";
        }
    };

    return (
        <form
            onSubmit={(evt) => {
                evt.preventDefault();
                handleOnClick();
            }}
        >
            <textarea
                value={token}
                onChange={(e) => setToken(e.target.value)}
                className={classes.textareaStyle}
            ></textarea>
            <input type="submit" value="Send" className={classes.buttonStyle} />
        </form>
    );
};

export default Reauth;
