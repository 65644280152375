import { QueryParam } from './../../models/payloads/queryParam';
import request from '../service';
import { ITitleCheckRequest, ITitleCheckResponse } from "../../models/payloads/titlePayload";
import { IFieldOption } from '../../models/appDetails';
import service from './genService'

const multipleSubmitService = {
    titleCheck: (cpmProductId: number): Promise<ITitleCheckResponse> => {
      const payload: ITitleCheckRequest = {
        cpmProductId
      } 
      return request.post(`/title/check`, payload)
    },
    getTitles: (keyword: string): Promise<IFieldOption[]> => {
      const param = new QueryParam("isMulti", "true");
      return service.dynamicRequest.postWithCancel("/title", keyword, [param]);
    }
};

export default multipleSubmitService;