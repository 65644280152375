import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

/// <reference path="overrides-mui.d.ts"/>
import * as muiPicker from "./overrides-mui"

const CentralTheme = createMuiTheme({
  palette: {
    primary: { "500": '#3DA8DF' }
  },
  overrides: {
    MuiPickersDay: {
      daySelected: {
        color: "#fff"
      }
    },
    MuiButton: {
      outlined: {
        fontSize: "14px",
        fontWeight: "bold",
        letterSpacing: 0,
        // lineHeight: "16px",
        borderRadius: "2px",
        border: "none !important",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
        // height: "30px",
        minWidth: "110px",
        paddingTop: "7px",
        textTransform: "none"
      },
      label: {
        '& .MuiPickersToolbarText-toolbarBtnSelected': {
          color: "#fff"
        }, 
        '& .MuiPickersToolbarText-toolbarTxt': {
          color: "#fff"
        }
      }
    },
    MuiDialog: {
      paper: {
        borderTop: "10px solid #3DA8DF",
        borderRadius: "1px"
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#000",
        height: 5
      },
    },
    MuiTab: {
      wrapper: {
        fontWeight: "bold",
        textTransform: "none"
      }
    },
    MuiCard: {
      root: {
        // borderTop: "10px solid #3DA8DF",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      }
    }
  }
})

export default CentralTheme;