import request from '../service';
import { QueryParam } from "../../models/payloads/queryParam";
import { SettingsManager } from "../../utils/settingsManager";
import axios, { CancelTokenSource } from "axios";

let cancelToken: CancelTokenSource;

const dynamicRequest = {
    post: (url: string, body: any, queryParams: QueryParam[] = []): Promise<any> => {
        url += getParams(queryParams);
        return request.post(url, body) 
    },
    postWithCancel: (url: string, body: any, queryParams: QueryParam[] = []): Promise<any> => {
        url += getParams(queryParams);
        if (cancelToken) {
            cancelToken.cancel();
        }
        cancelToken = axios.CancelToken.source();
        return request.post(url, body, cancelToken.token) 
    },
    get: (url: string, queryParams: QueryParam[] = []): Promise<any> => {
        url += getParams(queryParams);
        return request.get(url);
    }
};

const getParams = (queryParams: QueryParam[] = []) => {
    let params = "";
    queryParams.forEach(x => {
        params += `${params.includes("?") ? "&" : "?"}${x.key}=${x.value}`;
    });

    return params;
}

const UserInfo = {
    getInfo: (token: string): Promise<string> =>
      request.post('/user/info', token),
  };
  

export default {
	dynamicRequest,
    UserInfo
};