import { IKeyValuePair } from './workorderPayloadResponse';
import { IWorkOrderSection } from '../workOrderSection';
import { WorkOrderFieldValue } from '../workOrderFieldValue';
import { ActionType, FieldType } from '../enums';
import { IFieldOption } from '../appDetails';

export interface IWorkOrderPayloadRequest {
  id?: string;
  name: string;
  action: ActionType;
  fields: IFieldPayload[];
  section: ISectionPayloadRequest[];
  titles: ITitlePayloadRequest[];
  isAdminClone?: boolean;
  workOrderSourceId?: any;
}

export interface ISectionPayloadRequest {
  name: string;
  fields: IFieldPayload[],
  section: ISectionPayloadRequest[]
}

export interface IFieldPayload {
  name: string;
  type?: string;
  value: string | IFieldOption | IFieldOption[]
}

export interface ITitlePayloadRequest {
  key: number;
  value: string;
}

export class WorkOrderPayloadRequest implements IWorkOrderPayloadRequest {
  id?: string;
  name: string;
  action: ActionType;
  fields: IFieldPayload[];
  section: ISectionPayloadRequest[];
  titles: ITitlePayloadRequest[];
  isAdminClone?: boolean;

  constructor(type: string, action: ActionType, workOrder: Map<string, WorkOrderFieldValue>, sections: IWorkOrderSection[] = [], id?: string) {
    this.name = type;
    this.action = action;
    this.id = id;
    this.fields = [];
    this.section = [];
    this.titles = [];
    this.map(workOrder, sections);
  }

  addTitles = (titles: IFieldOption[]) => {
    this.titles = titles.map(x => {
      return {
        key: parseInt(x.value),
        value: x.label
      }
    })
    
    return this;
  }

  private map = (workOrder: Map<string, WorkOrderFieldValue>, sections: IWorkOrderSection[] = []) => {
    this.fields = this.mapFieldValuesToFieldPayload(workOrder);

    if (sections.length > 0) {
      this.section = sections.map(sec => this.getSection(sec))      
    }

    return this;
  }

  private getSection = (workOrderSection: IWorkOrderSection) => {
    return {
      name: workOrderSection.name,
      fields: this.mapFieldValuesToFieldPayload(workOrderSection.section),
      section: workOrderSection.subSections ? workOrderSection.subSections.map(sub => {
        return {
          name: sub.name,
          fields: this.mapFieldValuesToFieldPayload(sub.fieldValues),
          section: []
        }
      }) : []
    }
  }

  private mapFieldValuesToFieldPayload = (fieldValues: Map<string, WorkOrderFieldValue>) => {
    // .filter(x => !x.isNull)
    return [...fieldValues.values()].map(fieldValue => {
      return {
        name: fieldValue.name,
        type: fieldValue.type,
        value: fieldValue.getFieldValue()
      } as IFieldPayload
    });
  }
}