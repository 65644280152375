import { CircularProgress, IconButton, TextField } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import multipleSubmitService from "../../api/services/multipleSubmitService";
import useBulkSharedStyles from "./bulk-sharedStyles";
import ClearIcon from '@material-ui/icons/Clear';

export interface IProps {
  onSearch: (titles: any[]) => void
}

const BulkTitleSearch: React.FC<IProps> = ({
  onSearch
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");

  const classes = useBulkSharedStyles();

  const handleOnInputChange = (value: string) => {
    setValue(value);      
    onSearch([]);

    if (value.length > 0) {
      searchTitles(value);
    } 
  }

  const searchTitles = async (value: string) => {    
    try {
      setIsLoading(true);
      var titles = await multipleSubmitService.getTitles(value);
      onSearch(titles);
    } catch (err) {
      console.error(err);      
    }

    setIsLoading(false);    
  }

  const renderClearButton = () => {
    if (value) {
      return (
        <IconButton className={classes.clearBtn} onClick={() => handleOnInputChange("")}>
          <ClearIcon fontSize="small" />
        </IconButton>
      )
    }

    return null;
  }

  const renderFieldIndicator = () => {
    if (isLoading) {
      return <CircularProgress color="inherit" size={20} />
    }

    return <SearchOutlined color="disabled" fontSize="small" />
  }

  return (
    <TextField
      placeholder="Title Search"
      multiline
      fullWidth
      value={value}
      onChange={(e) => handleOnInputChange(e.target.value)}
      InputProps={{
        endAdornment: (
          <React.Fragment>
            {renderClearButton()}
            {renderFieldIndicator()}
          </React.Fragment>
        ),
      }}
    />
  )
}

export default BulkTitleSearch;