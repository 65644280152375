import React, { Fragment } from 'react';
import Icon from '@material-ui/core/Icon';
import useWorkOrderStyles from "../workOrderStyles";
import { IPageField } from '../../../../models/appDetails';
import { WorkOrderFieldValue } from '../../../../models/workOrderFieldValue';
import { Grid, GridSize, InputAdornment, TextField } from '@material-ui/core';
import { FieldType } from '../../../../models/enums';

interface Props {
	field: IPageField;
  initialValue?: string | null;
  gridSize: GridSize | boolean;
  isReadOnly?: boolean;
  setFormValue: (fieldValue: WorkOrderFieldValue) => void;
  setIsMissingDispatcher: (name: string, 
    dispatcher: React.Dispatch<React.SetStateAction<boolean>>) => void;
}

const InputField: React.FC<Props> = ({
  field,
  initialValue,
  gridSize,
  isReadOnly,
  setFormValue,
  setIsMissingDispatcher
}) => {
  const [value, setValue] = React.useState<string | null | undefined>(initialValue);
  const [isValueMissing, setIsMissing] = React.useState<boolean>(false);
  const classes = useWorkOrderStyles();
  
  const key = `${field.guid}-${field.name}`;
  const icon = field.icon;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let inputValue: string | null | undefined = e.target.value || "";

    if ((value || "").length == 0 && inputValue.trim().length == 0) {
      inputValue = "";
    }

    if (field.type === FieldType.Number && inputValue.length > 0) {
      if (inputValue[inputValue.length-1]?.match('[0-9]')) {
        inputValue = (Number(inputValue) || value || "").toString();
      } else {
        inputValue = value;
      }
    } 
    
    const fieldValue = new WorkOrderFieldValue(field, inputValue, true);
    setFormValue(fieldValue);
    setValue(fieldValue.textValue);
    
    if (fieldValue.isRequired && fieldValue.isNull) {
      setIsMissing(true)
    } else {
      setIsMissing(false)
    }
  }

  setIsMissingDispatcher(field.name, setIsMissing);

  return (
    <Fragment key={`${key}-fragment`}>
      <Grid key={`${key}-item`} item xs={gridSize} className={!field.show ? classes.hidden : ""}>
        <TextField id={key} type={"text"} key={key} name={field.name} 
          value={value || ''}                    
          required={field.required}
          error={isValueMissing}
          disabled={field.readOnly || false}
          // helperText={isValueMissing ? "" : null}
          onChange={(e) => handleOnChange(e)}
          label={field.label}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment key={`${key}-ia`} id={`${key}-ia`} position="end"><Icon color="disabled" fontSize="small">{icon}</Icon></InputAdornment>            
          }}
          inputProps={{...(field.maxLength && Number.isInteger(field.maxLength) ? {maxLength: field.maxLength} : {})}}
        />
      </Grid>
    </Fragment>
  )
  
}

export default InputField;