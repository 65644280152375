/*  Imports from Redux:
 applyMiddleware: Applies middleware to the dispatch method of the Redux store
 combineReducers: Merges reducers into one
 createStore: Creates a Redux store that holds the state tree
 Store: The TS Type used for the store, or state tree
 */
import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
/*  Thunk
Redux Thunk middleware allows you to write action creators that return a function instead of an action. The thunk can be used to delay the dispatch of an action, or to dispatch only if a certain condition is met. The inner function receives the store methods dispatch and getState as parameters.
*/
import { IAppState, appReducer } from './reducers/appReducer';
import { searchReducer, ISearchState } from './reducers/searchReducer';
import { workOrderReducer, IWorkOrderState } from './reducers/workOrderReducer';
// Import reducers and state type

// Create an interface for the application state
export interface IAppStore {
	appDetails: IAppState,
	searchResults: ISearchState,
	workOrder: IWorkOrderState
}

// Create the root reducer
const rootReducer = combineReducers<IAppStore>({
	appDetails: appReducer,
	searchResults: searchReducer,
	workOrder: workOrderReducer
});

function configureStore(): Store<IAppStore, any> {
	const store = createStore(rootReducer, undefined, composeWithDevTools(applyMiddleware(thunk)));
	return store;
}

const store = configureStore();

export default store;
