import React from 'react';
import { Dialog, Grid, DialogTitle, DialogContent, Button } from "@material-ui/core";

class Props {
  open: boolean | undefined = false;
  title: string | undefined = undefined;
  body: string | JSX.Element = "";
  onConfirm: (() => void) | undefined = undefined;
  onClose: (() => void) | undefined = undefined;
}

const ConfirmDialog: React.FC<Props> = ({
  open,
  title,
  body,
  onConfirm,
  onClose
}) => {

  return (
    <Dialog open={open ?? false} maxWidth="sm" fullWidth={true} onClose={onClose} aria-labelledby="confirm-dialog-title">
      <Grid container justify="center">
        <Grid item>
          <DialogTitle id="confirm-dialog-title" style={{ textAlign: "center" }}>
            <h3>{title}</h3>
          </DialogTitle>
          <DialogContent style={{ textAlign: "center" }}>
            {body}
            <br/>
            <div style={{ marginTop: 40, marginBottom: 20 }}>
              <Button onClick={onClose} variant="outlined">
                No
              </Button>
              &nbsp; &nbsp;
              <Button onClick={onConfirm} variant="outlined" color="primary">
                Yes
              </Button>
            </div>
          </DialogContent>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default ConfirmDialog;