import { Reducer } from 'redux';
import { IAppDetailsModel } from '../../models/appDetails';
import { AppActionTypes, AppActions } from '../actions/appAction';
import { v4 as uuidv4 } from 'uuid';
import { LocalStorageHelper } from '../../utils/localStorage-helper';

export interface IAppState extends IAppDetailsModel {
	token: string;
	isAuthenticated: boolean;
	accessDenied: boolean;
	userActionFlags: any;
}

const initialAppDetailsState: IAppState = {
	title: '',
	searchFields: [],
	tableFields: [],
	createTypes: [],
	initialized: false,
	isAuthenticated: false,
	token: '',
	accessDenied: false,
	userActionFlags: {}
};

export const appReducer: Reducer<IAppState, AppActions> = (state = initialAppDetailsState, action) => {
	  switch (action.type) {
      case AppActionTypes.APP_GET: 

			action.appDetails.createTypes.forEach(createType => {
				createType.createFields.forEach(fields => fields.guid = uuidv4());
				createType.section?.createFields.forEach(fields => fields.guid = uuidv4());
				createType.section?.section?.createFields.forEach(fields => fields.guid = uuidv4());
			});

			if (action.token) {
				LocalStorageHelper.setAuthToken(action.token)
      		}

			return {
				...state,
				title: action.appDetails.title,
				searchFields: action.appDetails.searchFields,
				tableFields: action.appDetails.tableFields,
				createTypes: action.appDetails.createTypes,
				tableSort: action.appDetails.tableSort,
				initialized: true,
				token: action.token ? action.token : state.token,
				isAuthenticated: action.token ? true : false,
				userActionFlags: action.userActionFlags
			}
			case AppActionTypes.DENY_ACCESS:
				return {
					...state,
					accessDenied: true
				}
			case AppActionTypes.RESET:
				return {
					title: '',
					searchFields: [],
					tableFields: [],
					createTypes: [],
					initialized: false,
					isAuthenticated: false,
					token: '',
					accessDenied: false,
					userActionFlags: {}
				}
			case AppActionTypes.UPDATE_TOKEN:
				LocalStorageHelper.setAuthToken(action.token)
				return {
					...state,
					isAuthenticated: true,
					token: action.token
				}
      default:
				return {
					...state
				}
		 }
  };
