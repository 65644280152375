import { ISectionForm, WorkOrderFieldValue } from "./workOrderFieldValue";
import { v4 as uuidv4 } from "uuid";
import { ICreateTypes } from "./appDetails";

export interface IWorkOrderSection {
  guid: string;
  name: string;
  section: Map<string, WorkOrderFieldValue>;
  subSections: ISectionForm[]
  deleted: boolean;

  clone: (asNew?: boolean, createTypeDef?: ICreateTypes) => IWorkOrderSection;
}

export class WorkOrderSection implements IWorkOrderSection {
  guid: string;
  name: string;
  section: Map<string, WorkOrderFieldValue>;
  subSections: ISectionForm[];
  deleted: boolean

  constructor() {
    this.guid = uuidv4();
    this.name = "";
    this.section = new Map();
    this.subSections = [];
    this.deleted = false;
  }

  public static createWorkOrderSection = (sectionId: string | null, name: string, section: Map<string, WorkOrderFieldValue>, subSections: ISectionForm[]) => {
    let woSection = new WorkOrderSection();
    woSection.guid = sectionId || uuidv4();
    woSection.name = name;
    woSection.section = section;
    woSection.subSections = subSections;

    return woSection;
  }

  clone = (asNew = false, createTypeDef?: ICreateTypes) => {
    let clonedSubSections: ISectionForm[] = [];

    this.subSections?.forEach(x => {
      clonedSubSections.push({
        guid: asNew ? uuidv4() : x.guid,
        deleted: x.deleted,
        name: x.name,
        fieldValues: new Map(x.fieldValues),
        dropDownsTriggered: x.dropDownsTriggered
      })
    })

    let clonedSection: IWorkOrderSection = WorkOrderSection.createWorkOrderSection(asNew ? uuidv4() : this.guid, this.name, new Map(this.section), clonedSubSections);

    if (asNew) {
      createTypeDef?.cloneExceptions.filter(x => x.split("/").length == 3).forEach(path => {
        const index = path.lastIndexOf("/");
        clonedSection.section.delete(path.substring(index+1))
      })
  
      if (createTypeDef?.section?.submitProperties.field) {
        const secField = createTypeDef.section?.createFields.find(x => x.name === createTypeDef.section?.submitProperties.field);
        if (secField) {
          clonedSection.section.set(secField.name, new WorkOrderFieldValue(secField, createTypeDef.section?.submitProperties.create));
        }
      }
  
      clonedSection.subSections.forEach(subSectionRow => {
        createTypeDef?.cloneExceptions.filter(x => x.split("/").length == 4).forEach(path => {
          const index = path.lastIndexOf("/");
          subSectionRow.fieldValues.delete(path.substring(index+1))
        })
  
        if (createTypeDef?.section?.section?.submitProperties.field) {
          const subSecField = createTypeDef?.section?.section?.createFields.find(x => x.name === createTypeDef.section?.section?.submitProperties.field);
          if (subSecField) {
            subSectionRow.fieldValues.set(subSecField.name, new WorkOrderFieldValue(subSecField, createTypeDef.section?.section?.submitProperties.create));
          }
        }

        WorkOrderFieldValue.preSetFields(createTypeDef?.section?.section?.createFields, subSectionRow.fieldValues);
      })
    }

    return clonedSection;
  }
}