import { CircularProgress, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import React, { Fragment } from "react";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		loadIndicator: {
			marginTop: "25%" 
		}
	}),
);

const LoadingIndicator = () => {
  const classes = useStyles()
  return(
    <Fragment>
      <Grid container spacing={3} className={classes.loadIndicator} justify="center">
        <Grid item>
          <CircularProgress color="inherit" />
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default LoadingIndicator;