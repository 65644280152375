import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React from "react";
import { IFieldOption } from "../../models/appDetails";
import useBulkSharedStyles from "./bulk-sharedStyles";
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';

export interface IProps {
  icon: "add" | "remove";
  titles: IFieldOption[];
  onItemClick: (title: IFieldOption, index: number) => void
}

const BulkTitlesList: React.FC<IProps> = ({
  icon,
  titles,
  onItemClick
}) => {

  const classes = useBulkSharedStyles();

  const handleOnItemClick = (title: IFieldOption, index: number) => {
    onItemClick(title, index);
  }

  const itemTitle = `${icon === "add" ? "Add" : "Remove"} title`;
  const listClassName = titles.length > 0 ? classes.titleList : undefined;

  return (
    <List className={listClassName}>
      {titles.map((x, i) => {
        return (
          <ListItem title={itemTitle} className={classes.titleItem} onClick={() => handleOnItemClick(x, i)}>
            <ListItemIcon className={classes.titleItemIcon}>
              {icon === "add" ? <AddBoxOutlined /> : <IndeterminateCheckBoxOutlinedIcon />}
            </ListItemIcon>
            <ListItemText key={`${icon}-title-${x.value}`} primary={x.label} />            
          </ListItem>
        )
      })}
    </List>
  )
}

export default BulkTitlesList;