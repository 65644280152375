import React, { Fragment } from 'react';
import Icon from '@material-ui/core/Icon';
import useWorkOrderStyles from "../workOrderStyles";
import { IPageField } from '../../../../models/appDetails';
import { WorkOrderFieldValue } from '../../../../models/workOrderFieldValue';
import { Grid, GridSize, InputAdornment, TextField } from '@material-ui/core';

interface Props {
	field: IPageField;
  initialValue?: string | null;
  gridSize: GridSize | boolean;
  isReadOnly?: boolean;
  setFormValue: (fieldValue: WorkOrderFieldValue) => void;
  setIsMissingDispatcher: (name: string, 
    dispatcher: React.Dispatch<React.SetStateAction<boolean>>) => void;
}

const MultiLineInputField: React.FC<Props> = ({
  field,
  initialValue,
  gridSize,
  isReadOnly,
  setFormValue,
  setIsMissingDispatcher
}) => {
  const [value, setValue] = React.useState<string | null | undefined>(initialValue);
  const [isValueMissing, setIsMissing] = React.useState<boolean>(false);
  const classes = useWorkOrderStyles();
  
  const key = `${field.guid}-${field.name}`;
  const icon = field.icon;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const fieldValue = new WorkOrderFieldValue(field, e.target.value);
    setFormValue(fieldValue);
    setValue(fieldValue.textValue);

    if (fieldValue.isRequired && fieldValue.isNull) {
      setIsMissing(true)
    } else {
      setIsMissing(false)
    }
  }

  setIsMissingDispatcher(field.name, setIsMissing);

  //TODO HARDCODED GRIDSIZE FOR MULTILINETEXT FOR DEMO PURPOSES
  return (
    <Fragment key={`${key}-fragment`}>

      <Grid key={`${key}-item`} item xs={6} className={!field.show ? classes.hidden : ""}>
        <TextField id={key} type={field.type || "text"} key={key} name={field.name} 
          value={value || ''}
          required={field.required}
          error={isValueMissing}
          // helperText={isValueMissing ? "" : null} 
          multiline
          disabled={field.readOnly || false}
          onChange={(e) => handleOnChange(e)}
          label={field.label}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment key={`${key}-ia`} id={`${key}-ia`} position="end"><Icon color="disabled" fontSize="small">{icon}</Icon></InputAdornment>
          }}
        />
      </Grid>
    </Fragment>
  )
  
}

export default MultiLineInputField;