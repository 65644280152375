import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import WorkOrderPage from '../pages/workOrderPage';
import WorkOrderListPage from '../pages/workOrderListPage';
import { useDispatch, useSelector } from "react-redux";
import { IAppStore } from "../state/store";
import appService from '../api/services/appTypeService';
import { getAction, denyAction } from "../state/actions/appAction";
import LoadingIndicator from "./loadingIndicator";
import { Notifications } from "./shared/notifications/Notifications";
import { AxiosResponse } from "axios";
import sortByRules from "../utils/dropdown-helper";
import { ISortOptions } from "../models/appDetails";
import MessageDialog from "./shared/notifications/MessageDialog";
import { Reauth } from "../pages/Reauth";

const AppDetailsWrapper = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const appDetails = useSelector((state: IAppStore) => state.appDetails);
	const notifications = new Notifications(React.useRef(null));

  function getAppDetails() {
		appService.AppDetails.info().then(payload => {
      payload.app.createTypes.forEach(ct => {
        ct.createFields.filter(cf => cf.sortOptions).forEach(cf => {
          cf.options = sortByRules(cf.options, cf.sortOptions as ISortOptions);
        })

        if (ct.section?.name) {
          ct.section.createFields.filter(cf => cf.sortOptions).forEach(cf => {
            cf.options = sortByRules(cf.options, cf.sortOptions as ISortOptions);
          })

          if (ct.section?.section?.name) {
            ct.section.section.createFields.filter(cf => cf.sortOptions).forEach(cf => {
              cf.options = sortByRules(cf.options, cf.sortOptions as ISortOptions);
            })
          }
        }
      })
			dispatch(getAction(payload.app, payload.token, payload.userActionFlags));      
		}).catch((err: AxiosResponse) => {
			console.log(err);
      const defaultMsg = "There was a problem retrieving app details";
      
      switch (err?.status) {
        case 400:
          if (err?.data == "User has no access") 
            dispatch(denyAction());
          else
            notifications.error(err?.data || defaultMsg); 
          break;
        case 404:
          notifications.error(err?.data); 
          break;
        case 401:
          history.push("/login");
          break;
        default:
          if (err?.status !== 401) 
            notifications.error(err?.data || defaultMsg); 
          break;
      }
		});
	}

  const renderLoadIndicator = () => {
    return (
      <LoadingIndicator />
    )
  }

  if (appDetails.accessDenied) {
    return (
      <MessageDialog 
        open={appDetails.accessDenied}
        hideCloseBtn={true}
        title="Access Unauthorized"
        body="Your user is not authorized to interact with this portal"
      />
    )
  }

  if (!appDetails.initialized) {
    getAppDetails();
    return renderLoadIndicator();
  }

  return (
    <Switch>
      <Route path='/' component={WorkOrderListPage} exact />
      <Route path='/reauth' component={Reauth} />
      <Route path={['/wo/:action/:type/:id?']} component={WorkOrderPage} />
    </Switch>
  )
}

export default AppDetailsWrapper;