export class LocalStorageHelper {

  static hasToken = () => {
    return LocalStorageHelper.getAuthToken() != null
  }

  static getAuthToken = () => {
    return localStorage.getItem("wo-token-storage");
  }

  static setAuthToken = (token: string) => {
    return localStorage.setItem("wo-token-storage", token);
  }

  static clearAuthToken = () => {
    localStorage.removeItem("wo-token-storage");
  }

  static get = (name: string) => {
    return localStorage.getItem(name);
  }

  static set = (name: string, value: string) => {
    return localStorage.setItem(name, value);
  }
}