import React, { Fragment } from 'react';
import useWorkOrderStyles from "../workOrderStyles";
import { IPageField } from '../../../../models/appDetails';
import { WorkOrderFieldValue } from '../../../../models/workOrderFieldValue';
import { Grid, GridSize } from '@material-ui/core';
import DateFnsUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Moment } from 'moment';

interface Props {
	field: IPageField;
  initialValue?: Date | null;
  gridSize: GridSize | boolean;
  isReadOnly?: boolean;
  setFormValue: (fieldValue: WorkOrderFieldValue) => void;
  setIsMissingDispatcher: (name: string, 
    dispatcher: React.Dispatch<React.SetStateAction<boolean>>) => void;

}

const DateField: React.FC<Props> = ({
  field,
  initialValue,
  gridSize,
  isReadOnly,
  setFormValue,
  setIsMissingDispatcher
}) => {
  const [value, setValue] = React.useState<Date | null | undefined>(initialValue);
  const [isValueMissing, setIsMissing] = React.useState<boolean>(false);
  const classes = useWorkOrderStyles();
  
  const key = `${field.guid}-${field.name}`;
  
  const handleOnChange = (momentDate: Moment | null) => {
    momentDate?.startOf("day");
    const fieldValue = new WorkOrderFieldValue(field, momentDate?.toDate());
    setFormValue(fieldValue);
    setValue(fieldValue.dateValue);

    if (fieldValue.isRequired && fieldValue.isNull) {
      setIsMissing(true)
    } else {
      setIsMissing(false)
    }
  }

  setIsMissingDispatcher(field.name, setIsMissing);

  return (
    <Fragment key={`${key}-fragment`}>
      <Grid key={`${key}-item`} item xs={gridSize} className={!field.show ? classes.hidden : ""}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className={classes.dateField}
            disableToolbar
            variant="inline"
            format={field?.format || "MM/DD/yyyy"}
            margin="normal"
            autoOk={true}
            disabled={field.readOnly || false}
            fullWidth
            id={key}
            label={field.label}
            value={value ? value : null}
            required={field.required}
            error={isValueMissing}
            // helperText={isValueMissing ? "" : null} 
            onChange={(date) => handleOnChange(date)}            
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Fragment>
  )
}

export default DateField;