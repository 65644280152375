import React, { Fragment } from 'react';
import PrimarySearchAppBar from './components/navBar';
import { Container, createStyles, makeStyles, Theme, ThemeProvider } from '@material-ui/core';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import CentralTheme from './Theme/central-theme';
import AppDetailsWrapper from './components/appDetailsWrapper';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import PrivateRoute from './components/auth/privateRoute';
import LoginPage from './pages/loginPage';
import { useDispatch } from 'react-redux';
import { updateTokenAction } from './state/actions/appAction';
import { LocalStorageHelper } from "./utils/localStorage-helper";
import { Reauth } from './pages/Reauth';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingLeft: "0",
			paddingRight: "0"
		}
	}),
);

const App = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const token = LocalStorageHelper.getAuthToken();

	if (token) {
		dispatch(updateTokenAction(token))
	}

	return (
		<ThemeProvider theme={CentralTheme}>
			<Fragment>
				<ToastContainer position='top-right' hideProgressBar={true}
					newestOnTop={true} closeButton={false} pauseOnHover={true}
					closeOnClick={true} autoClose={3000} style={{ minWidth: 320, width: "auto" }} />
				<PrimarySearchAppBar></PrimarySearchAppBar>
				<Fragment>
					<Container maxWidth={false} className={classes.root}>
						<Switch>
							<Route path='/login' component={LoginPage} />
							<Route path='/auth/myidcallback' component={LoginPage} />
							<Route path='/reauth' component={Reauth} />
							<PrivateRoute path={['/', '/wo/:action/:type/:id?']} loginPath="/login">
								<AppDetailsWrapper />
							</PrivateRoute>
						</Switch>
					</Container>
				</Fragment>
			</Fragment>
		</ThemeProvider>
	);
}

export default App;
