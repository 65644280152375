import React from "react";
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  Button, 
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { IActionStatus, IActionStatusFormFields, IFieldOption, ISection } from "../../models/appDetails";
import { ISectionForm, WorkOrderFieldValue } from "../../models/workOrderFieldValue";
import WorkOrderForm from "../shared/work-order-form/workOrderForm";
import WorkOrderSubSectionEditor from "./workOrderSubSectionEditor";
import { Notifications } from "../shared/notifications/Notifications";
import { IWorkOrderSection, WorkOrderSection } from "../../models/workOrderSection";
import { IFieldsValidationResult } from "../../models/workOrder";
import { FieldType } from "../../models/enums";

interface IProps {
  mainValues?: Map<string, WorkOrderFieldValue>,
  sectionValues: IWorkOrderSection,
  section: ISection;
  open: boolean;
  newRecord: boolean;
  actionStatus?: IActionStatus;
  closeDialog: () => void;
  saveSection: (workOrderSection: IWorkOrderSection) => void;
}

interface ISectionState {
  expanded: { 
    section: boolean,
    subSection: boolean 
  },
  sectionForm: Map<string, WorkOrderFieldValue>,
  subSections: ISectionForm[],
  dropDownsTriggered: boolean
}

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    dialogTitle: {
      marginBottom: -20
    },
    dialogGridContainer: {
      padding: "10px 24px 25px", 
      marginBottom: 0
    },
    dialogContent: {
      padding: "25px 0",
      borderTop: "1px solid lightgray"
    },
    subHeader: {
			fontWeight: "bold",
			color: "gray",
			opacity: "0.8",
      paddingLeft: "25px"
		},
    accordionSummary: {
      backgroundColor: "#EAEAEA",
      minHeight: 0,
      '&.Mui-expanded': {
        minHeight: 0
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: 0
      }
    },
    accordionDetails: {
      padding: "40px",
      flexDirection: "column",
    },
    accordionDetailsSubSection: {
      paddingTop: "20px",
    },
    tipoSummary: {
      opacity: 0.4,
      fontWeight: "bold"
    },
    accordion: {
      boxShadow: "none"
    }
  })
)

const WorkOrderSectionEditor: React.FC<IProps> = ({
  mainValues,
  sectionValues,
  section,
  open,
  newRecord,
  actionStatus,
  closeDialog,
  saveSection
}) => {

  const classes = useStyles();
  const notifications = new Notifications(React.useRef(null));
  const [sectionState, setSection] = React.useState<ISectionState>({
    expanded: { section: true, subSection: true },
    sectionForm: sectionValues.section,
    subSections: sectionValues.subSections || [],
    dropDownsTriggered: false
  });

  let sectionFormFields: IActionStatusFormFields | undefined;
  let subSectionFormFields: IActionStatusFormFields | undefined;
  let subSectionDropdowns: string[] = [];

  if (actionStatus?.formFields) {
    sectionFormFields = {
      readOnly: actionStatus?.formFields?.readOnly || false,
      readOnlyExceptions: [],
      showOnly: actionStatus?.formFields?.readOnly || true,
      showOnlyExceptions: [],
      addSection: actionStatus?.formFields?.addSection || false,
      isNew: newRecord
    }

    subSectionFormFields = {
      readOnly: actionStatus?.formFields?.readOnly || false,
      readOnlyExceptions: [],
      showOnly: actionStatus?.formFields?.readOnly || true,
      showOnlyExceptions: [],
      addSection: actionStatus?.formFields?.addSection || false,
      isNew: newRecord
    }
  }

  let triggerDropdownChange: (fieldValue: WorkOrderFieldValue, justUpdateMap: boolean, updateParentField: boolean) => void;
  let subSectionTriggerDropdowns: () => void;

  if (actionStatus) {
    actionStatus.formFields?.readOnlyExceptions
    .filter(x => x.includes("/"))
    .forEach(fieldName => {
      const splited = fieldName.split(`/`)

      if (splited.length == 3) {
        sectionFormFields?.readOnlyExceptions.push(splited[2]);
        return
      } 
      
      if (splited.length == 4) {
        subSectionFormFields?.readOnlyExceptions.push(splited[3]);
      }
    })

    actionStatus.formFields?.showOnlyExceptions.filter(x => x.includes("/"))
    .forEach(fieldName => {
      const splited = fieldName.split(`/`)

      if (splited.length == 3) {
        sectionFormFields?.showOnlyExceptions.push(splited[2]);
        return
      } 
      
      if (splited.length == 4) {
        subSectionFormFields?.showOnlyExceptions.push(splited[3]);
      }
    })
  }

  const isReadOnly = () => {
    if (!actionStatus?.formFields || newRecord) {
      return false;
    }

    return sectionFormFields?.readOnly && sectionFormFields?.readOnlyExceptions.length === 0 
      && subSectionFormFields?.readOnlyExceptions.length === 0
  }

  const attachDropdownChange = (formDropdownChange: (fieldValue: WorkOrderFieldValue, justUpdateMap: boolean) => void) => {
    triggerDropdownChange = formDropdownChange;
  }

  const attachSubSectionDropdownChanges = (callback: () => void) => {
    subSectionTriggerDropdowns = callback;
  }

  const handleOnAccordionChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    sectionState.expanded[panel] = newExpanded;
    setSection({...sectionState});
  };

  let sectionValidationDispatcher: {
		requiredValidation: () => IFieldsValidationResult,
		additionalValidation: () => IFieldsValidationResult,
	}

  let subSectionValidationDispatcher: {
		requiredValidation: () => IFieldsValidationResult[],
		additionalValidation: () => IFieldsValidationResult[],
	}

  const updateSectionFieldValues = (fieldValue: WorkOrderFieldValue) => {
		sectionState.sectionForm.set(fieldValue.name, fieldValue);
    if (subSectionDropdowns.includes(fieldValue.name) && subSectionTriggerDropdowns) {
      subSectionTriggerDropdowns();
    }
	}

  WorkOrderFieldValue.preSetFields(section.createFields, sectionState.sectionForm);

  if (newRecord) {
    if (section.submitProperties.field) {
      const field = section.createFields.find(x => x.name === section.submitProperties.field);
      if (field) {
        sectionState.sectionForm.set(field.name, new WorkOrderFieldValue(field, section.submitProperties.create));
      }
    }
  }

  if (mainValues) {
    let fieldsToTrigger: WorkOrderFieldValue[] = [];
    section.createFields.forEach(x => {
      let fieldValue = sectionState.sectionForm.get(x.name);

      if (x.prePopulate) {
        const splited = x.prePopulate.split(`/`)
        let mainValue: WorkOrderFieldValue | undefined;
        if (splited.length == 2) {
          mainValue = mainValues.get(splited[1]);
          
          //scenarios
          // airlines selected & popup diferent value -> trigger
          // airlines not selected & popup selected -> trigger
          // airlines not selected & popup not selected -> don't trigger

          if (!fieldValue || fieldValue.isNull) {
            fieldValue = new WorkOrderFieldValue(x, mainValue?.getFieldValue());
            sectionState.sectionForm.set(x.name, fieldValue);
            if (fieldValue.hasDependencies) {
              fieldsToTrigger.push(fieldValue);
            }
            return
          }

          if (!x.show) {
            if (mainValue && !mainValue.isNull && mainValue.getFlatValue() != fieldValue?.getFlatValue()) {
              fieldValue = new WorkOrderFieldValue(x, mainValue?.getFieldValue());
              sectionState.sectionForm.set(x.name, fieldValue);
              if (fieldValue.hasDependencies) {
                fieldsToTrigger.push(fieldValue);
              }
              return
            }
  
            if ((!mainValue || mainValue.isNull) && fieldValue) {
              fieldValue = new WorkOrderFieldValue(x, mainValue?.getFieldValue());
              sectionState.sectionForm.set(x.name, fieldValue);
              if (fieldValue.hasDependencies) {
                fieldsToTrigger.push(fieldValue);
              }
              return
            }
  
          }

        }
      }
    })

    setTimeout(() => {        
      fieldsToTrigger.forEach(fieldValue => {
        if (triggerDropdownChange && !sectionState.dropDownsTriggered) {
          triggerDropdownChange(fieldValue, false, false);
        }
      })
      sectionState.dropDownsTriggered = true;
    }, 500);
  }

  subSectionDropdowns = section.section?.createFields.filter(x => x.prePopulate && x.dependencies?.child).map(x => {
    const index = x.prePopulate.lastIndexOf("/")
    return x.prePopulate.substring(index > -1 ? index+1 : 0);
  }) ?? [];

  const updateSubSectionFieldValues = (subSection: ISectionForm[]) => {
    sectionState.subSections = subSection
	}

  const setSectionValidationDispatcher = (formKey: string, requiredDispatcher: () => IFieldsValidationResult, additionalValidationDispatcher: () => IFieldsValidationResult) => {
		sectionValidationDispatcher = {
			requiredValidation: requiredDispatcher,
			additionalValidation: additionalValidationDispatcher
		};
	}

  const setSubSectionValidationDispatcher = (dispatchers: () => IFieldsValidationResult[], additionalDispatchers: () => IFieldsValidationResult[]) => {
		subSectionValidationDispatcher = {
      requiredValidation: dispatchers,
      additionalValidation: additionalDispatchers
    };
	}

  const validateSection = () => {
    let result = sectionValidationDispatcher.requiredValidation();

		if (result.fields.length === 1) {
			notifications.error(`${section.display} Required field "${result.fields[0].name}" is missing!`);
			return false
		}

		if (result.fields.length > 0) {
			notifications.error(`${section.display} Please enter required fields!`);
			return false
		}

    result = sectionValidationDispatcher.additionalValidation();

		if (result.fields.length > 0) {
			notifications.error(result.fields[0].message || "");
			return false
		}

    if (section.section?.required && sectionState.subSections.length == 0) {
      notifications.error(`${section.section?.display} are missing`);
      return false
    }

		return true;
  }

  const validateSubsection = () => {

    let results = subSectionValidationDispatcher.requiredValidation()

    if (results.find(x => x.fields.length > 0)) {
      notifications.error(`${section.section?.display} Please enter required fields!`);
      return false
    }
    
    results = subSectionValidationDispatcher.additionalValidation();

		if (results.find(x => x.fields.length > 0)) {
			notifications.error(results.find(x => x.fields.length > 0)?.fields[0].message || "");
			return false
		}

    return true;
  }

  const handleOnClose = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>, isSaveAction?: boolean) => {
    if (isSaveAction) {
      if (!validateSection() || !validateSubsection()) {
        setSection({
          ...sectionState,
          sectionForm: sectionState.sectionForm,
          subSections: sectionState.subSections
        });
        return;
      }   
    }

    closeAndCleanDialog(isSaveAction)
  };

  const closeAndCleanDialog = (isSave?: boolean) => {
    if (isSave) {
      const workOrderSection = WorkOrderSection.createWorkOrderSection(sectionValues.guid, section.name, sectionState.sectionForm, sectionState.subSections)
      saveSection(workOrderSection);
      return
    }

    closeDialog();    
  }

  const renderSubSection = (subSection?: ISection) => {
    if (subSection) {
      return (
        <Accordion square 
          expanded={sectionState.expanded.subSection} 
          onChange={handleOnAccordionChange('subSection')}
          className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="subSection-content"
            id="subSection-header"
            className={classes.accordionSummary}>
            <Typography className={classes.tipoSummary}>{subSection.display}</Typography>
          </AccordionSummary>
          <AccordionDetails className={`${classes.accordionDetails} ${classes.accordionDetailsSubSection}`}>
            <WorkOrderSubSectionEditor
              subSection={subSection}
              mainValues={mainValues}
              sectionValues={sectionState.sectionForm}
              subSectionValues={sectionState.subSections}
              actionFormFields={subSectionFormFields}
              setSubSectionValues={updateSubSectionFieldValues}
              setSectionValidationDispatcher={setSubSectionValidationDispatcher}
              setTriggerDropdownsChange={attachSubSectionDropdownChanges}
            />
          </AccordionDetails>
        </Accordion>
      )
    }
  }

  const renderSaveBtn = () => {
    if (isReadOnly()) {
      return null
    }

    return (
      <Button variant="outlined" color="primary" 
        onClick={(e) => handleOnClose(e, true)}>
        Save
      </Button>
    )
  }

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true} onClose={() => handleOnClose()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        <Grid container justify="space-between" className={classes.dialogGridContainer}>
          <Grid item>
            Add {section.display} & {section.section?.display}
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => handleOnClose()}>
              Close
            </Button>
            &nbsp; &nbsp;
            {renderSaveBtn()}
            {/* <Button variant="outlined" color="primary" 
              onClick={(e) => handleOnClose(e, true)}>
              Save
            </Button> */}
          </Grid>
        </Grid>
        
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <span className={classes.subHeader}>
          Enter the fields below to set up {section.display} and {section.section?.display}:
        </span>
        <Accordion square 
          expanded={sectionState.expanded.section} 
          onChange={handleOnAccordionChange('section')}
          className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="section-content"
            id="section-header"
            className={classes.accordionSummary}>
            <Typography className={classes.tipoSummary}>{section.display}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <WorkOrderForm
              key={`form-${section.name}`}
              workOrderType={section.name}
              fields={section?.createFields || []}
              initialfieldValues={sectionState.sectionForm}
              actionFormFields={sectionFormFields}
              setParentFieldValues={updateSectionFieldValues}
              setValidationDispatcher={setSectionValidationDispatcher}
              triggerDropdownChange={attachDropdownChange}
              gridSize={3}
            />
          </AccordionDetails>
        </Accordion>
        {renderSubSection(section.section)}
      </DialogContent>
    </Dialog>
  );
}

export default WorkOrderSectionEditor;