import React, { Fragment, useEffect } from "react";
import { Grid, GridSize, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Autocomplete, AutocompleteInputChangeReason, createFilterOptions, FilterOptionsState } from "@material-ui/lab";
import CircularProgress from '@material-ui/core/CircularProgress';
import service from '../../../../api/services/genService';
import { IFieldOption, IPageField, ISortOptions } from "../../../../models/appDetails";
import { WorkOrderFieldValue } from '../../../../models/workOrderFieldValue';
import { FieldType } from '../../../../models/enums';
import { SearchOutlined } from "@material-ui/icons";
import ClearIcon from '@material-ui/icons/Clear';
import useWorkOrderStyles from "../workOrderStyles";
import { Notifications } from "../../notifications/Notifications";
import { AxiosError } from 'axios';
import sortByRules from "../../../../utils/dropdown-helper";
import { matchSorter } from "match-sorter";

interface IProps {
  field: IPageField;
  initialValue?: IFieldOption | null;
  initialOptions: IFieldOption[]
  gridSize: GridSize | boolean;
  isReadOnly?: boolean;
  setFormValue: (fieldValue: WorkOrderFieldValue, justUpdateMap: boolean) => void;
  setIsMissingDispatcher: (name: string, 
    dispatcher: React.Dispatch<React.SetStateAction<boolean>>) => void;
  shouldClearValue: boolean;
  setFieldEmpty: (fieldName: string, isEmpty: boolean) => void;
}

const TypeAheadField: React.FC<IProps> = ({
  field,
  initialValue,
  initialOptions,
  gridSize,
  isReadOnly,
  setFormValue,
  setIsMissingDispatcher,
  shouldClearValue,
  setFieldEmpty,
}) => {
  const titleFieldName = "catORtitle";
  const startingOptions = initialOptions.length > 0 ? initialOptions : (field.options || []);
  const [inputValue, setInputValue] = React.useState(initialValue?.label || '');
  const [value, setValue] = React.useState<IFieldOption | null | undefined>(initialValue);
  const [optionsState, setOptionsState] = React.useState<{
    options: IFieldOption[],
    isLoading: boolean
  }>({
    options: startingOptions, 
    isLoading: false
  });
  const [isValueMissing, setIsMissing] = React.useState<boolean>(false);

  const classes = useWorkOrderStyles();

  const sendValueToForm = (value: IFieldOption | null, 
    justUpdateMap: boolean = false) => {
    const fieldValue = new WorkOrderFieldValue(field, value);
    setFormValue(fieldValue, justUpdateMap);

    if (fieldValue.isRequired && fieldValue.isNull) {
      setIsMissing(true)
    } else {
      setIsMissing(false)
    }
  }

  if (optionsState.options.length == 0 && value != null) {
    setOptionsState({
      ...optionsState,
      options: [value]
    });
  }

  const searchOptions = (value: string) => {
    setOptionsState({
      options: [],
      isLoading: true
    });

    service.dynamicRequest.postWithCancel(
			field.endpoint, 
			value)
		.then(result => {
			setOptionsState({
        options: [...sortByRules(result || [], field.sortOptions as ISortOptions)],
        isLoading: false
      });
		}).catch((err: AxiosError) => {
			console.error(err)
      setOptionsState({
        ...optionsState,
        isLoading: false
      });
		});
  }

  const handleOnChange = (item: IFieldOption | null) => {
    sendValueToForm(item);
    setValue(item as IFieldOption);

    if (item) {
      setInputValue(item.label);
    }else{
      setInputValue("");
      setValue({ label: '', value: '' });
    }

  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  setIsMissingDispatcher(field.name, setIsMissing);

  const handleOnInputChange = (value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === "input") {
      setInputValue(value);
      if (value.length > 0) {
        searchOptions(value);
      }
    } else if (reason === "clear") {
      setInputValue("");   
      setValue({ label: '', value: '' });
      setFieldEmpty(titleFieldName, true);
    }

    console.log(value);
  }

  const key = `${field.guid}-${field.name}`;

  const filterOptions = (options: IFieldOption[], { inputValue }) => {
    return options;
    // if (!inputValue || !inputValue.length) {
    //   return options
    // }
  
    // const terms = (inputValue as string).split(' ')
    // if (!terms) {
    //   return options
    // }
  
    // // reduceRight will mean sorting is done by score for the _first_ entered word.
    // return terms.reduceRight(
    //   (results, term) => matchSorter(results, term, {
    //     keys: ["label"]
    //   }),
    //   options,
    // )
  }

  useEffect(() => {
    if (shouldClearValue && (field.name === titleFieldName)) {
      handleOnChange(null);
    }
  }, [shouldClearValue]);

  return (
    <Fragment key={`${key}-fragment`}>
      <Grid key={`${key}-item`} item xs={gridSize} className={!field.show ? classes.hidden : ""}>
        <Autocomplete
          id={key}
          key={key}
          fullWidth
          filterOptions={filterOptions}
          disabled={field.readOnly || false}
          inputValue={inputValue}
          value={value || { label: '', value: '' }}
          noOptionsText='No results'
          onChange={(e, value) => handleOnChange(value as IFieldOption)}
          onKeyPress={handleKeyPress}
          onInputChange={(e, value, reason) => handleOnInputChange(value, reason)}
          getOptionSelected={(option, selected) => option.value === selected.value}
          getOptionLabel={(o: IFieldOption) => o.label}
          options={optionsState.options}
          loading={optionsState.isLoading}
          forcePopupIcon={false}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              label={field.label}
              required={field.required}
              error={isValueMissing}
              // helperText={isValueMissing ? "" : null}
              multiline
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {params.InputProps.endAdornment}
                    {inputValue && !field.readOnly ? 
                      <IconButton className={classes.clearBtn} onClick={() => handleOnInputChange("", "clear")}>
                        <ClearIcon fontSize="small" />
                      </IconButton> : null}
                    {optionsState.isLoading ? <CircularProgress color="inherit" size={20} /> : <SearchOutlined color="disabled" fontSize="small"  />}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Fragment>
  );
}

export default TypeAheadField;