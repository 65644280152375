import * as React from 'react';
import { Grid, TextField } from '@material-ui/core';
import useWorkOrderStyles from '../work-order-form/workOrderStyles';
import { Autocomplete } from '@material-ui/lab';
import { computeDateRange, DateRange } from './dateSpanFilter.types';

interface Props {
    options: string[];
    selectedOption: string;
    setSelectedOption: Function;
    setDatespan: Function;
}

const DateSpanFilter: React.FC<Props> = ({
    options,
    selectedOption,
    setSelectedOption,
    setDatespan
  }) => {

  const classes = useWorkOrderStyles();

  const computeDatespan = (selectedOption: string) : DateRange => {
		if(selectedOption === 'Last 7 days'){return computeDateRange(7);}
		else if(selectedOption === 'Last 15 days'){return computeDateRange(15);}
		else if(selectedOption === 'Last 30 days'){return computeDateRange(30);}
		else if(selectedOption === 'Last 90 days'){return computeDateRange(90);}
		return {
      from: null,
      to: null,
    };
	};
  
  const handleChange = (selectedOption: string) => {    
    const selectedDatespan = computeDatespan(selectedOption);
    setSelectedOption(selectedOption);
    setDatespan(selectedDatespan);
  };

  return (    
     <React.Fragment key='datepsan-filter-fragment'>
      <Grid key='datepsan-filter-item' item xs={true} className={classes.searchDateSpanFilter}>
       <Autocomplete
         id='datepsan-filter'
         key='datepsan-filter'
         fullWidth
         getOptionSelected={(option, selected) => option === selected}
         disableClearable={true}
         options={options}
         renderInput={(params) => 
           <TextField {...params}
             label='Updated in'
             required={false}
             InputProps={{
               ...params.InputProps,
               endAdornment: (
                 <React.Fragment>
                   {params.InputProps.endAdornment}
                 </React.Fragment>
               ),
             }}/>
         }
         value={selectedOption}
         onChange={(e, value) => handleChange(value)}
       />
     </Grid>
   </React.Fragment>   
  );
}

export default DateSpanFilter;