import request from '../service';
import axios, { CancelTokenSource } from "axios";
import { IFieldAssociationRequest, IFieldAssociationResponse } from '../../models/payloads/fieldAssociationPayload';

const dropdownService = {
    getOptions: (payload: IFieldAssociationRequest): Promise<IFieldAssociationResponse> => {      
      return request.post(`/parentField`, payload)
    }
};

export default dropdownService;