export type DateRange = {
	from: Date | null;
	to: Date | null;
};

export const computeDateRange = (lastDays: number): DateRange => {    
    return {
        from: new Date(Date.now() - lastDays * 24 * 60 * 60 * 1000),
        to: new Date()
    };
};